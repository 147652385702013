import React, { useState, useEffect } from "react";
import Header from "../../components/header/header";
import Login from "./components/login/login";
import { Toaster, toast } from "react-hot-toast";
import { apiCelCast } from "../../sessions/apiCelCast";
import { getAccessToken } from "../../sessions/apiCelCashToken";
import "./index.css";
import ScapeButton from "../../components/scapeButton/scape";
import { apiYouCast } from "../../sessions/apiYoucast";

const DataChange = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [customerDetail, setCustomerDetail] = useState(null);
  const [token, setToken] = useState(null);
  const [ativaCor, setAtivaCor] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function posicaoScroll() {
      if (window.scrollY > -1) {
        setAtivaCor(true);
      } else {
        setAtivaCor(false);
      }
    }

    window.addEventListener("scroll", posicaoScroll);

    return () => {
      window.removeEventListener("scroll", posicaoScroll);
    };
  }, []);

  useEffect(() => {
    const storedEmail = isLoggedIn ? localStorage.getItem("userEmail") : null;

    if (storedEmail) {
      fetchTokenAndLogin(storedEmail);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn && token) {
      fetchCustomerDetail();
    }
  }, [isLoggedIn, token]);

  const fetchTokenAndLogin = async (email) => {
    try {
      const fetchedToken = await getAccessToken();
      setToken(fetchedToken);

      handleLoginSuccess(email);
    } catch (error) {
      console.error("Erro ao obter o token:", error);
      toast.error("Erro ao obter o token");
    }
  };

  const fetchCustomerDetail = async () => {
    try {
      const storedEmail = localStorage.getItem("userEmail");

      if (storedEmail && token) {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        };

        const response = await apiCelCast.get(
          `/customers?emails=${encodeURIComponent(storedEmail)}&startAt=0&limit=100&order=createdAt.asc`,
          { headers }
        );

        const customer = response.data.Customers[0];
        if (customer) {
          setCustomerId(customer.myId);
          setCustomerDetail(customer);
          // console.log("CUSTOMERR ", response)
        }
      }
    } catch (error) {
      console.error("Erro ao obter os detalhes do cliente", error);
    }
  };

  const handleLoginSuccess = (email) => {
    setLoggedIn(true);
  };

  const handleChangeCardData = async () => {
    try {
      if (customerId && token) {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await apiCelCast.put(
          `/subscriptions/${customerId}/myId`,
          {
            mainPaymentMethodId: "creditcard",
          },
          {
            headers,
          }
        );

        if (response.data && response.data.Subscription.paymentLink) {
          const paymentLink = response.data.Subscription.paymentLink;

          window.open(paymentLink, "_blank");

          toast.success("Dados do cartão alterados com sucesso");
        } else {
          toast.error("Não foi possível obter o link de pagamento");
        }
      } else {
        toast.error("Não foi possível encontrar o customerId ou token");
      }
    } catch (error) {
      console.error("Erro ao alterar dados do cartão", error);
      toast.error("Erro ao alterar dados do cartão");
    }
  };

  const handleCancelSubscription = async () => {
    setLoading(true);
    try {
      const storedEmail = localStorage.getItem("userEmail");

      // console.log("STOREDEMAIL--> ", storedEmail);

      if (customerId && token) {
        const headers = {
          Authorization: `Bearer ${token}`,
          
        };

        // Parâmetros de consulta incluindo o customerId
        const params = {
          startAt: 0,
          limit: 100,
          order: "createdAt.desc",
          myIds: customerId,
        };

        // console.log("CUSTOMRTID--> ", customerId);

        // Obtendo os detalhes da assinatura com parâmetros de consulta e cabeçalhos
        const subscriptionDetails = await apiCelCast.get("/subscriptions", {
          params,
          headers,
        });

        // console.log("Resposta da API:", subscriptionDetails); // Verificar a resposta da API

        // Verificar se a resposta é válida e se há assinaturas
        if (
          subscriptionDetails &&
          subscriptionDetails.data &&
          subscriptionDetails.data.Subscriptions.length > 0
        ) {
          const subscriptions = subscriptionDetails.data.Subscriptions;

          // Supondo que haja apenas uma assinatura por customerId para simplificar
          const subscription = subscriptions[0];

          // console.log("OQ RETORNA DA API--->", subscription);

          const mainPayment = subscription.mainPaymentMethodId;

          const startDateString = subscription.firstPayDayDate;
          // console.log("PRIMEIRO PAGAMENTO--->", startDateString);

          // Obter a data atual sem as informações de hora, minuto, segundo e milissegundo
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const currentMonth = String(currentDate.getMonth() + 1).padStart(
            2,
            "0"
          );
          const currentDay = String(currentDate.getDate()).padStart(2, "0");
          const formattedCurrentDate = `${currentYear}-${currentMonth}-${currentDay}`;

          // console.log("Data atual formatada:", formattedCurrentDate);

          // Calcular a diferença em dias
          const startDateParts = startDateString.split("-");
          const currentDateParts = formattedCurrentDate.split("-");

          const startDateObj = new Date(
            startDateParts[0],
            startDateParts[1] - 1,
            startDateParts[2]
          );
          const currentDateObj = new Date(
            currentDateParts[0],
            currentDateParts[1] - 1,
            currentDateParts[2]
          );

          const timeDiff = Math.abs(
            currentDateObj.getTime() - startDateObj.getTime()
          );
          // console.log("DIFF--->", timeDiff);

          const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
          // console.log("DIFFDAY--->", diffDays);

          // const timeDiff = Math.abs(currentDate.getTime() - startDate.getTime());
          // console.log("DIFF--->", timeDiff)

          // const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
          // console.log("DIFFDAY--->", diffDays)

          if (diffDays <= 7) {
            // Se estiver dentro dos 7 dias, fazer o estorno
            const transactionId = subscription.Transactions[0].galaxPayId;
            //
            const reverseBody = {
              valueToReverse: subscription.value,
            };

            // console.log("VALOR DO ID DA TRANSAÇÃO", transactionId);

            // console.log("metodo ", mainPayment);

            if (mainPayment === "pix") {
              await apiCelCast.delete(`/subscriptions/${customerId}/myId`, {
                headers,
              });

              await apiYouCast.delete(`/cancelProduct/${storedEmail}`);

              setLoading(false);
              toast.success("Assinatura cancelada com sucesso");

            } else {
              // Chamar a API para fazer o estorno
              await apiCelCast.put(
                `/transactions/${transactionId}/galaxPayId/reverse`,
                reverseBody,
                { headers }
              );

              await apiYouCast.delete(`/cancelProduct/${storedEmail}`);

              await apiCelCast.delete(`/subscriptions/${customerId}/myId`, {
                headers,
              });

              setLoading(false);
              toast.success("Estorno e cancelamento feito com sucesso!");
            }
          } else {
            // Se estiver após os 7 dias, apenas cancelar a assinatura
            await apiCelCast.delete(`/subscriptions/${customerId}/myId`, {
              headers,
            });


            setLoading(false);
            toast.success("Assinatura cancelada com sucesso");
          }
        } else {
          setLoading(false);
          toast.error("Não foram encontradas assinaturas para este cliente");
        }
      } else {
        setLoading(false);
        toast.error("Não foi possível encontrar o customerId ou token");
      }
    } catch (error) {
      console.error("Erro ao cancelar a assinatura:", error);
      setLoading(false);
      toast.error("Erro ao cancelar a assinatura");
    }
  };

  return (
    <div className="main">
      <div className="contentMain">
        {customerDetail ? (
          <>
            <Header acao={ativaCor}></Header>
            {console.log("o que tem no customer", customerDetail)}
            <div className="profileContainer">
              <div className="profileBackground"></div>

              <div className="profileInfoOptionsContainer">
                <div className="profileInfo">
                  <div className="profileAvatar">
                    <img
                      src={require("../../assets/spy.png")}
                      alt="avatar"
                    ></img>
                  </div>

                  <div className="profileDetails">
                    <h2>{customerDetail.name}</h2>
                    <h4>{customerDetail.emails}</h4>
                  </div>
                </div>

                <div className="profileOptions">
                  <section className="profileSection">
                    <details className="profileDetail">
                      <summary className="profileSummary">Meus dados</summary>
                      <div className="profileDetailedContainer">
                        <h3>Dados Pessoais:</h3>

                        <div className="profileDetailedContent">
                          <p>Nome: {customerDetail.name}</p>
                        </div>
                        <div className="profileDetailedContent">
                          <p>Email: {customerDetail.emails}</p>
                        </div>

                        <div className="profileDetailedContent">
                          <p>CPF: {customerDetail.document}</p>
                        </div>

                        <h3>Telefone:</h3>

                        <div className="profileDetailedContent">
                          <p>Telefone: {customerDetail.phones}</p>
                        </div>

                        <h3>Endereço:</h3>

                        <div className="profileDetailedContent">
                          <p>Endereço: {customerDetail.Address.street}</p>
                        </div>
                        <div className="profileDetailedContent">
                          <p>Número: {customerDetail.Address.number}</p>
                        </div>

                        <div className="profileDetailedContent">
                          <p>
                            Complemento:{" "}
                            {customerDetail.Address.complement === ""
                              ? "Nenhum"
                              : customerDetail.Address.complement}
                          </p>
                        </div>
                        <div className="profileDetailedContent">
                          <p>Bairro: {customerDetail.Address.neighborhood}</p>
                        </div>
                        <div className="profileDetailedContent">
                          <p>Cidade: {customerDetail.Address.city}</p>
                        </div>

                        <div className="profileDetailedContent">
                          <p>Estado: {customerDetail.Address.state}</p>
                        </div>
                        <div className="profileDetailedContent">
                          <p>CEP: {customerDetail.Address.zipCode}</p>
                        </div>
                      </div>
                    </details>

                    <details className="profileDetail">
                      <summary className="profileSummary">Meus cartões</summary>
                      <div className="profileContentCard">
                        <img
                          src={require("../../assets/credit_card.jpg")}
                          alt="credit card"
                        ></img>
                        <button
                          onClick={handleChangeCardData}
                          className="changeOptions"
                        >
                          Alterar dados do cartão
                        </button>
                      </div>
                    </details>

                    <details className="profileDetail">
                      <summary className="profileSummary">
                        Minhas Assinaturas
                      </summary>
                      {customerDetail && customerDetail.status !== "active" ? (
                        <>
                          <p>Você não tem nenhuma assinatura ATIVA</p>
                        </>
                      ) : (
                        <>
                          <p>Você tem uma assinatura ATIVA</p>
                          <button
                            onClick={handleCancelSubscription}
                            className="changeOptions"
                          >
                            {loading ? "Enviando..." : " Cancelar assinatura"}
                          </button>
                        </>
                      )}
                    </details>
                  </section>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Login
            onLoginSuccess={(email) => {
              fetchTokenAndLogin(email);
              toast.success("Login bem-sucedido");
            }}
          />
        )}
      </div>
      <Toaster />
      <ScapeButton />
    </div>
  );
};

export default DataChange;
