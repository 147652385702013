import React, { useEffect, useState, useRef } from "react";
import Footer from "../../components/footer/footer";
import "./index.css";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header/header";
import ScapeButton from "../../components/scapeButton/scape";
import {OfferCards} from "../../components/offerCards/offerCards";

function Offers() {
  const navigate = useNavigate();

  const [ativaCor, setAtivaCor] = useState(false);

  useEffect(function () {
    function posicaoScroll() {
      if (window.scrollY > 10) {
        setAtivaCor(true);
      } else {
        setAtivaCor(false);
      }
    }

    window.addEventListener("scroll", posicaoScroll);
  }, []);

  return (
    <div className="offerDetailContainer">
      <Header acao={ativaCor} />
      <ScapeButton />
      <div className="offerContentDetails">
        <div className="offerTitleDetails">
          <h1>Nossos Planos</h1>
          <h3>Escolha o plano ideal para você</h3>
        </div>

        <OfferCards />
      </div>

      <Footer></Footer>
    </div>
  );
}

export default Offers;
