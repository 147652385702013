import React, { useState } from "react";
import PropTypes from "prop-types";
import { apiYouCast } from "../../../../sessions/apiYoucast";
import { Toaster, toast } from "react-hot-toast";
import "../login.css";
import Header from "../../../../components/header/header";
import "../../../../components/registerForm/registerForm.css"

const Login = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const performAPICall = async () => {
    try {
      localStorage.setItem("userEmail", email);
      const response = await apiYouCast.post("/loginUser", {
        email: email,
        password: password,
      });

      const { data } = response;

      if (response.status === 202) {
        onLoginSuccess();
      } else if (response.status === 403) {
        toast.error("Credenciais inválidas");
      } else {
        console.error("Erro ao processar a requisição", response);
        toast.error("Erro ao processar a requisição");
      }
    } catch (error) {
      console.error("Erro ao processar a requisição", error);
      toast.error("Erro ao processar a requisição");
    }
  };

  return (
    <>
    <Header/>

    <div className="loginContainer">
      <div className="loginLeftContainer">

      </div>

      
      <div className="loginRightContainer">
        <div className="loginRightContent">
          <h1>Central do assinante</h1>
          <h3>Faça login para ter acesso aos seus dados!</h3>
          <div className="style-input-group">
            <label className="style-input-filled">
              <input
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="style-input-label">Email</span>
              <span className="style-input-helper">Digite seu email</span>
            </label>
          </div>

          <div className="style-input-group">
            <label className="style-input-filled">
              <input
                type="password"
                name="senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="style-input-label">Senha</span>
              <span className="style-input-helper">Digite sua senha</span>
            </label>
          </div>
          <div className="forgotPasswordContainer">
            <a href="/resetPassword" className="forgotPasswordLink">Esqueci minha senha</a>

          </div>
          <button onClick={performAPICall}>Entrar</button>

          <div className="footerCopyrightContent">
            <p>
              Todos os direitos reservados © 2023, <strong>SEXPRIVÉ</strong>
            </p>
          </div>

        </div>

      </div>
      

      <Toaster />
    </div>
    </>
  );
};

Login.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired,
};

export default Login;