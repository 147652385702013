import axios from "axios";

export const fetchAddressFromApi = async (cep) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL_CEP}/${cep}/json/`
    );
    if (response.data.erro) {
      throw new Error("CEP inválido");
    }
    return response.data;
  } catch (error) {
    return error;
  }
};
