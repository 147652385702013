
import toast, { Toaster } from "react-hot-toast";
import "./index.css";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { apiCelCast } from "../../sessions/apiCelCast";

function getToken() {
    return new Promise(async (resolve, reject) => {
      try {
        const username = process.env.REACT_APP_USERNAME_CEL_CASH;
        const password = process.env.REACT_APP_PASSWORD_CEL_CASH;
        const basicAuth = btoa(`${username}:${password}`);
        const endpoint = "token";
        const requestBody = {
          grant_type: "authorization_code",
          scope:
            "customers.read customers.write plans.read plans.write transactions.read transactions.write webhooks.write balance.read balance.write cards.read cards.write card-brands.read subscriptions.read subscriptions.write charges.read charges.write boletos.read",
        };
        const response = await apiCelCast.post(`${endpoint}`, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${basicAuth}`,
          },
        });
  
        const responseData = response.data;
        localStorage.setItem("access_token", responseData.access_token);
  
        resolve();
      } catch (tokenError) {
        console.error("Erro na chamada à API do token:", tokenError);
        reject(new Error("Erro na requisição do token"));
      }
    });
  }
  

function PaymentPix() {
    const [transactionStatus, setTransactionStatus] = useState('');
    const navigate = useNavigate();
    const [clientData, setClientData] = useState({});
    const [formattedValue, setFormattedValue] = useState('');
    const [imgPix, setImgPix] = useState('');
    const [qrCode, setQrCode] = useState('');
  
    useEffect(() => {
      const storedClientData = JSON.parse(localStorage.getItem('@data-user')) || {};
      const storedValue = localStorage.getItem('@value') || '';
     let storedImgPix = localStorage.getItem('@imgPix') || '';
      let storedQrCode = localStorage.getItem('@qrCode') || '';
  
      // Remove as aspas duplas da string imgPix, se presentes
      storedImgPix = storedImgPix.replace(/^"(.*)"$/, '$1');
      storedQrCode = storedQrCode.replace(/^"(.*)"$/, '$1');
  
      setClientData(storedClientData);
      formatValue(storedValue);
      setImgPix(storedImgPix);
      setQrCode(storedQrCode)
    }, []);
  
    // Função para formatar o valor
    const formatValue = (value) => {
      if (value) {
        const floatValue = parseFloat(value) / 100;
        setFormattedValue(floatValue.toLocaleString('pt-BR', { minimumFractionDigits: 2 }));
      }
    };

      // Função para copiar o QR code para a área de transferência
      const copyQRCodeToClipboard = () => {
        navigator.clipboard.writeText(qrCode);
       toast.success('QR code copiado para a área de transferência!');
    };

    useEffect(() => {
        const interval = setInterval(() => {
          checkTransactionStatus();
        }, 10000); // Verifica o status a cada 10 segundos
    
        return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
      }, []);

      const myId = JSON.parse(localStorage.getItem("@id-user"));
    
      const checkTransactionStatus = async () => {
        try {
            await getToken();

            const token = localStorage.getItem("access_token");
    
            const endpoint = `transactions?startAt=0&limit=100&order=createdAt.desc&timestamp=${Date.now()}`;
    
            const response = await apiCelCast.get(`${endpoint}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
    
            const transacoes = response.data.Transactions;
            const transacaoDoUsuario = transacoes.find(
              (transacao) =>
                transacao.Subscription &&
                transacao.Subscription.Customer &&
                transacao.Subscription.Customer.myId === myId &&
                transacao.status
            );

            //  console.log("transation ", transacaoDoUsuario)

          if (transacaoDoUsuario ) {
            setTransactionStatus(transacaoDoUsuario.status);
          }
        } catch (error) {
          console.error('Erro ao verificar o status da transação:', error);
        }
      };
    
      useEffect(() => {
        if (transactionStatus === 'payedPix') {
          navigate('/congratulations'); // Redireciona para a página de sucesso de pagamento

        } 
      }, [transactionStatus]);
  
    return (
      <body className="bodyPix">
        <header className="navbarPix">
          <img
            src="https://data.cel.cash/files/logo-company/2023-11-29-09-48-17sexprive1004753807.png?58029"
            alt="logo sex prive"
            className="imgNav"
          />
          <div></div>
          <img
            src="https://app.galaxpay.com.br/view/images/logo-clara.png?3679"
            alt="logo cel cash"
            className="imgNav"
          />
        </header>
        <main className="mainPix">
          <div className="containerPix">
            <div className="textBoxPix">
              <h2 className="h2Pix">DADOS DA EMPRESA</h2>
              <span className="spanPix">Empresa: YOUCAST REPRESENTACOES LTDA</span>
              <span className="spanPix">CNPJ: 48.461.449/0001-83</span>
            </div>
            <div className="textBoxPix">
              <h2 className="h2Pix">DADOS DO PAGAMENTO</h2>
              <span className="spanPix">Cliente: {clientData.name}</span>
              <span className="spanPix">Periodicidade: Mensal</span>
              <span className="spanPix">Valor: {formattedValue}</span>
            </div>
          </div>
          <div className="qrCodeBoxPix">
            <div className="qrCodeCard">
              {imgPix && <img src={imgPix} alt="qr code de pagamento" className="imgPix" />}
            </div>
            <div>
              <ol>
                <li className="liPix">Escaneie o qr code</li>
                <li className="liPix">Efetue o pagamento</li>
                <li className="liPix">Aguarde a confirmação de sua assinatura</li>
                <li className="liPixCopy" onClick={copyQRCodeToClipboard}>Copiar QR code</li>
              </ol>
            </div>
          </div>
          <Toaster />
        </main>
      </body>
    );
  }
  
  export default PaymentPix;





