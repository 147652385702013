import React from "react";
import Footer from "../../components/footer/footer";
import "./index.css";

function PrivacyPolicy() {
  return (
    <div className="privacyContainer">
      <div className="privacyTitle">
        <h1>Política de Privacidade</h1>
      </div>
      <div className="usageTermsContent">
        <div className="usageListContainer">
          <h2>Política Privacidade</h2>
          <ul>
            <li>
              A sua privacidade é importante para nós. É política do Sex Privé
              respeitar a sua privacidade em relação a qualquer informação sua
              que possamos coletar no site{" "}
              <a href="https://spp-2023.uc.r.appspot.com/">Sex Privé</a>, e
              outros sites que possuímos e operamos.
            </li>
          </ul>
          <ul>
            <li>
              Solicitamos informações pessoais apenas quando realmente
              precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios
              justos e legais, com o seu conhecimento e consentimento. Também
              informamos por que estamos coletando e como será usado.
            </li>
          </ul>
          <ul>
            <li>
              Apenas retemos as informações coletadas pelo tempo necessário para
              fornecer o serviço solicitado. Quando armazenamos dados,
              protegemos dentro de meios comercialmente aceitáveis ​​para evitar
              perdas e roubos, bem como acesso, divulgação, cópia, uso ou
              modificação não autorizados.
            </li>
          </ul>
          <ul>
            <li>
              Não compartilhamos informações de identificação pessoal
              publicamente ou com terceiros, exceto quando exigido por lei.
            </li>
          </ul>
          <ul>
            <li>
              O nosso site pode ter links para sites externos que não são
              operados por nós. Esteja ciente de que não temos controle sobre o
              conteúdo e práticas desses sites e não podemos aceitar
              responsabilidade por suas respectivas&nbsp;
              <a
                href="https://politicaprivacidade.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                políticas de privacidade
              </a>
            </li>
          </ul>
          <ul>
            <li>
              Você é livre para recusar a nossa solicitação de informações
              pessoais, entendendo que talvez não possamos fornecer alguns dos
              serviços desejados.
            </li>
          </ul>
          <ul>
            <li>
              O uso continuado de nosso site será considerado como aceitação de
              nossas práticas em torno de privacidade e informações pessoais. Se
              você tiver alguma dúvida sobre como lidamos com dados do usuário e
              informações pessoais, entre em contacto connosco.
            </li>
          </ul>
          <ul>
            <li>
              O serviço Google AdSense que usamos para veicular publicidade usa
              um cookie DoubleClick para veicular anúncios mais relevantes em
              toda a Web e limitar o número de vezes que um determinado anúncio
              é exibido para você.
            </li>
          </ul>
          <ul>
            <li>
              Para mais informações sobre o Google AdSense, consulte as FAQs
              oficiais sobre privacidade do Google AdSense.
            </li>
          </ul>
          <ul>
            <li>
              Utilizamos anúncios para compensar os custos de funcionamento
              deste site e fornecer financiamento para futuros desenvolvimentos.
              Os cookies de publicidade comportamental usados ​​por este site
              foram projetados para garantir que você forneça os anúncios mais
              relevantes sempre que possível, rastreando anonimamente seus
              interesses e apresentando coisas semelhantes que possam ser do seu
              interesse.
            </li>
          </ul>
          <ul>
            <li>
              Vários parceiros anunciam em nosso nome e os cookies de
              rastreamento de afiliados simplesmente nos permitem ver se nossos
              clientes acessaram o site através de um dos sites de nossos
              parceiros, para que possamos creditá-los adequadamente e, quando
              aplicável, permitir que nossos parceiros afiliados ofereçam
              qualquer promoção que pode fornecê-lo para fazer uma compra.
            </li>
          </ul>
        </div>
        <div className="usageListContainer">
          <h2>Compromisso do Usuário</h2>
          <ul>
            <li>
              O usuário se compromete a fazer uso adequado dos conteúdos e da
              informação que o Sex Privé oferece no site e com caráter
              enunciativo, mas não limitativo:
            </li>
          </ul>
          <ul>
            <li>
              A) Não se envolver em atividades que sejam ilegais ou contrárias à
              boa fé a à ordem pública;
            </li>
          </ul>
          <ul>
            <li>
              B) Não difundir propaganda ou conteúdo de natureza racista,
              xenofóbica, <a href="https://166bet.br.com">166bet</a> ou azar,
              qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou
              contra os direitos humanos;
            </li>
          </ul>
          <ul>
            <li>
              C) Não causar danos aos sistemas físicos (hardwares) e lógicos
              (softwares) do Sex Privé, de seus fornecedores ou terceiros, para
              introduzir ou disseminar vírus informáticos ou quaisquer outros
              sistemas de hardware ou software que sejam capazes de causar danos
              anteriormente mencionados.
            </li>
          </ul>
        </div>
        <div className="usageListContainer">
          <h2>Mais informações</h2>
          <ul>
            <li>
              Esperemos que esteja esclarecido e, como mencionado anteriormente,
              se houver algo que você não tem certeza se precisa ou não,
              geralmente é mais seguro deixar os cookies ativados, caso interaja
              com um dos recursos que você usa em nosso site.
            </li>
          </ul>
          <ul>
            <li>Esta política é efetiva a partir de&nbsp;27 December 2023</li>
          </ul>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default PrivacyPolicy;
