import React from "react";
import { useRef, useState } from "react";
import "./index.css";

function ContentWarning() {
  const [isSubscribed, setIsSubscribed] = useState(false);

  const ref = useRef(null);

  const handleClick = () => {
    if (ref.current.checked) {
      console.log("✅ Checkbox is checked");
      setIsSubscribed("");
      localStorage.setItem("agreedWarning", true);
      window.location.reload();
    } else {
      console.log("⛔️ Checkbox is NOT checked");
      setIsSubscribed(
        "Você deve ler e concordar com os termos de uso antes de prosseguir.",
      );
    }
  };

  return (
    <div className="warningContainer">
      <div className="bgdImage"></div>
      <div className="warningContent">
        <div className="logoContainer">
          <img src={require("../../assets/logo_sexprive.png")}></img>
        </div>

        <div className="warningContainerInfo">
          <div className="warningTextContainer">
            <h1>Você precisa ser maior de 18 anos para acessar!</h1>
            <h2>
              O SexPrivé é destinado ao público adulto. Ao concordar com os
              termos e clicar abaixo, você confirma ter 18 anos ou mais.
            </h2>

            <div className="termsToAccept checkInfo">
              <label htmlFor="subscribe">
                <input
                  ref={ref}
                  type="checkbox"
                  id="subscribe"
                  name="subscribe"
                />
              </label>
              <h3>
                Concordo com os{" "}
                <a className="terms" href="/termos-de-uso" target="_blank">
                  termos de uso
                </a>{" "}
                do SexPrivé.
              </h3>
            </div>

            <button onClick={handleClick} className="warningBtn">
              Acessar Conteúdo
            </button>
            <h3 className="warningInfo">{isSubscribed}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentWarning;
