import api from "../api";

const result = await api
  .post("getHomepageInfo", {})
  .then(async function (result) {
    return result;
  })
  .catch((error) => {
    if (error) {
      console.log(error);
    }
  });

export default result;
