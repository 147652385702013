import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import ScapeButton from "../../components/scapeButton/scape";
import OfferCards from "../../components/offerCards/offerCards";
import "./index.css";
import RegisterForm from "../../components/registerForm/registerForm";

function Register() {
  const navigate = useNavigate();

  const [ativaCor, setAtivaCor] = useState(false);

  useEffect(function () {
    function posicaoScroll() {
      if (window.scrollY > 10) {
        setAtivaCor(true);
      } else {
        setAtivaCor(false);
      }
    }

    window.addEventListener("scroll", posicaoScroll);
  }, []);

  return (
    <div className="offerDetailContainer">
      <Header acao={ativaCor} />
      <ScapeButton />

      <div className="offerContentDetails">
        <div className="offerTitleDetails">
          <h1>Registre sua conta!</h1>
          <h3>Preencha seus dados para prosseguir</h3>
        </div>

        <RegisterForm />
      </div>

      <Footer></Footer>
    </div>
  );
}

export default Register;
