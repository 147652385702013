import React from "react";
import Footer from "../../components/footer/footer";
import "./index.css";

function UsageTerms() {
  return (
    <div className="usageTermsContainer">
      <div className="usageTermsTitle">
        <h1>Termos de Uso</h1>
      </div>

      <div className="usageTermsContent">
        <div className="usageListContainer">
          <h2>1. DESCRIÇÃO DO SERVIÇO</h2>
          <ul>
            <li>
              Por meio da contratação de uma assinatura recorrente (“Assinatura
              Recorrente”) ou contratação avulsa (“Diária”) (Diária e Assinatura
              Recorrente, em conjunto, “Assinatura”), você (“Você”, incluindo as
              variações “Seu(s)” e “Sua(s)”) terá acesso, por meio do Serviço, a
              um conjunto de conteúdos audiovisuais compostos por filmes adultos
              nacionais e internacionais que poderão ser alterados de tempo em
              tempo (“Conteúdo”), oferecido na modalidade de vídeo sob demanda
              e/ou streaming
            </li>
          </ul>
        </div>

        <div className="usageListContainer">
          <h2>2. ACEITAÇÃO DE TERMOS E POLÍTICAS</h2>
          <ul>
            <li>
              Ao utilizar o Serviço, Você confirma que concorda com: (i) estes
              Termos de Uso; (ii){" "}
              <a href="/politica-de-privacidade">Política de Privacidade</a> do
              SEXPRIVÉ e autoriza que Seus dados sejam utilizados de acordo com
              tais políticas e (iii) o contrato de assinatura aplicável. Por
              isso, é importante que Você leia tais termos com muita atenção.
            </li>
          </ul>
        </div>
        <div className="usageListContainer">
          <h2>3. CADASTRO</h2>
          <ul>
            <li>
              Para contratar e usufruir do Serviço, Você precisa,
              concomitantemente: <br></br>
              (i) Ter 18 (dezoito) anos completos, no mínimo, e ser plenamente
              capaz. <br></br>
              (ii) Ser assinante do Serviço via: Operadoras de TV Paga
              (“Operadoras”), Lojas de venda de aplicativos (“Lojas de
              Aplicativos”) ou Quaisquer terceiros autorizados (“Terceiros
              Autorizados”) <br></br>
              (iii) Possuir o cadastro solicitado pelos Canais de Venda,
              conforme o caso; <br></br>
              (iv) Realizar cadastro no Serviço, atendendo todas as exigências
              exigidas para a contratação por cada Canal de Venda <br></br>
              (v) Estar em dia com os pagamentos de Sua Assinatura.
            </li>
            <li>
              Você deverá ainda checar com o Canal de Venda, conforme o caso,
              eventuais particularidades para contratação do Serviço e acesso ao
              Conteúdo.
            </li>

            {/*NECESSARIO ALTERAR PARA O EMAIL CORRETO AQUI */}
            <li>
              Como parte de Seu cadastro, Você é responsável pela manutenção do
              sigilo dos dados de Sua conta para acesso ao Serviço,
              responsabilizando-se, ainda, por toda atividade realizada na Sua
              conta. Você jamais poderá usar a conta de terceiros, nem deverá
              fornecer Seus dados para que terceiros utilizem Sua conta. Você
              concorda que comunicará imediatamente através do e-mail
              contato@sexprive.com.br qualquer violação de segurança ou uso não
              autorizado de Sua conta.
            </li>
            <li>
              Ao fazer login no Serviço via os Canais de Venda, conforme
              aplicável, Você concorda que o respectivo Canal de Venda
              compartilhe com a SEXPRIVÉ e/ou quaisquer terceirizados as Suas
              informações relativas ao Serviço.
            </li>
          </ul>
        </div>
        <div className="usageListContainer">
          <h2>4. ACESSO E USO DO SERVIÇO E DO CONTEÚDO</h2>
          <ul>
            <li>
              <strong>Alteração do Conteúdo:</strong> A escolha do Conteúdo é
              Nossa prerrogativa exclusiva. O Conteúdo é atualizado regularmente
              e Nós podemos, a qualquer tempo, exemplificativamente, (i)
              excluir, (ii) incluir, (iii) alterar, (iv) atualizar, e/ou (v)
              substituir qualquer conteúdo no Serviço, sem que isso caracterize
              violação a estes Termos de Uso e/ou ao contrato de assinatura
              aplicável e sem que seja necessária qualquer notificação por Nós a
              Você nesse sentido.
            </li>
            <li>
              <strong>Limitação do Serviço e do Conteúdo:</strong> Você poderá
              acessar o Serviço, exclusivamente no Brasil e por meio de
              determinados dispositivos conectados à Internet, tais como
              televisores, computadores, aparelhos móveis e /ou outros aparelhos
              compatíveis com o Serviço (“Aparelhos Compatíveis”), que
              permitirão a visualização do Conteúdo. O Conteúdo é orientado para
              adultos, não sendo apropriado para menores de 18 (dezoito) anos.
              Nos termos da Lei 8.069/1990 – Estatuto da Criança e do
              Adolescente, exibir o Conteúdo a crianças e adolescentes é
              proibido e constitui crime.
            </li>
            <li>
              <strong>Indisponibilidade do Conteúdo:</strong> Caso haja alguma
              restrição e/ou proibição decorrente de disposições contratuais,
              legais e/ou judiciais que impeçam a disponibilização do Conteúdo,
              no todo ou em parte, Nos reservamos o direito de não
              disponibilizar o conteúdo impedido, sem que isso importe em
              descumprimento por Nossa parte
            </li>
            <li>
              <strong>Limitação de acessos simultâneos:</strong> A visualização
              do Conteúdo estará disponível de forma limitada a dois acessos
              simultâneos ao Serviço.
            </li>
            <li>
              <strong>Qualidade do Conteúdo:</strong> Dependendo do Aparelho
              Compatível (inclusive, de sua versão) e de outros fatores como a
              Sua localização, a largura de banda disponível e a velocidade de
              conexão à Internet, a qualidade da imagem do Conteúdo pode variar.
              Os serviços de Internet e os recursos do Aparelho Compatível
              (inclusive, de sua versão) utilizados também impactarão na
              disponibilidade em alta definição (HD), ultra alta definição (4K)
              e High Dynamic Range (HDR) e outras tecnologias que venham a ser
              implementadas no Serviço. Além disso, nem todo Conteúdo está
              disponível em todos os formatos e estará sujeito à compatibilidade
              do Aparelho Compatível utilizado.
            </li>
            <li>
              O Conteúdo será disponibilizado apenas nos Aparelhos Compatíveis
              que atendam os Requisitos Mínimos exigidos pelo Serviço.
            </li>
          </ul>
        </div>
        <div className="usageListContainer">
          <h2>5. LICENÇA E PROPRIEDADE INTELECTUAL</h2>
          <ul>
            <li>
              <strong>Licença:</strong> Concedemos a Você uma licença não
              exclusiva, intransferível, não sublicenciável e limitada para
              assistir o Conteúdo por meio do Serviço, apenas para uso pessoal,
              sem fins comerciais e desde que integralmente atendidas as
              condições previstas nestes Termos de Uso e Você concorda, desde
              já, que o uso do Serviço não confere a Você qualquer título,
              participação ou propriedade em relação ao Serviço e/ou ao
              Conteúdo. Você reconhece que o Serviço tem o fim específico para
              uso privado e concorda, ainda, em não utilizar o Serviço e/ou
              qualquer parcela do Conteúdo em exibições públicas, com ou sem
              cobrança de ingresso ou qualquer outra forma de remuneração, ainda
              que não monetária.
            </li>
            <li>
              <strong>Propriedade Intelectual:</strong> O Serviço e o Conteúdo
              são protegidos por direitos de propriedade intelectual. Assim,
              Você não poderá, em nenhuma circunstância, fazer o seguinte: (i)
              utilizar o Conteúdo com o intuito de cometer fraude ou estimular a
              prática de condutas ilícitas ou contrárias à moral; (ii) alterar a
              configuração de Sua máquina para utilização do Serviço e/ou do
              Conteúdo, sendo proibido, inclusive, alterar os endereços IP
              (Internet Protocol) de rede ou de correio eletrônico, na tentativa
              de responsabilizar terceiros ou ocultar a identidade ou autoria ou
              alterar a sua geolocalização; (iii) publicar, inserir, reproduzir,
              traduzir e disponibilizar, de forma não autorizada, qualquer
              parcela do Conteúdo ou do Serviço; (iv) remover, alterar,
              interferir, evitar ou de qualquer forma modificar marca d´água,
              copyright, símbolo, marca ou qualquer outro sinal indicativo de
              propriedade inserido no Conteúdo, e/ou quaisquer direitos e/ou
              mecanismos de proteção associados ao Conteúdo; (v) copiar, fazer
              download, capturar, reproduzir, arquivar, distribuir, fazer
              upload, publicar, modificar, traduzir, exibir, transmitir,
              apropriar, incorporar o Conteúdo, (vi) incorporar o Conteúdo a
              qualquer aplicativo de software ou hardware, exibir e retransmitir
              o Conteúdo, ou torná-lo disponível através de frames ou links;
              (vii) distribuir, anunciar ou desenvolver um negócio a partir do
              Conteúdo ou do Serviço; (viii) utilizar o Serviço ou o Conteúdo
              para criação de obras derivadas ou nele baseadas, tais como
              montagens, mash-ups, vídeos similares e materiais de marketing ou
              merchandising; (ix) modificar, remover, interferir ou de qualquer
              forma alterar o Serviço, em especial o seu player de vídeo e a sua
              tecnologia, incluindo mecanismo de proteção de conteúdo ou
              controle de acesso, (x) usar qualquer meio automatizado para
              acessar o Serviço, (xi) manipular e/ou adulterar o Conteúdo ou o
              Serviço, ou de qualquer forma alterar o código de programação do
              Serviço, (xii) decompilar, realizar engenharia reversa ou
              desmembrar qualquer software produtos ou processos disponíveis no
              Serviço, (xiii) fazer uso de ferramentas de data mining, coleta de
              dados ou extração de dados, e (xiv) no contexto do Serviço ou de
              qualquer forma associado a este, divulgar imagens, mensagens ou
              qualquer classe de conteúdo de cunho pornográfico, pedófilo,
              racista, violento, discriminatório ou ainda ofensivos à honra, à
              vida privada, à imagem, à intimidade pessoal e familiar, ou de
              qualquer maneira ofensivos ou que façam apologia às drogas ou
              sejam contrários às leis, à ordem, à moral e aos bons costumes.
            </li>
          </ul>
        </div>
        <div className="usageListContainer">
          <h2>6. SUA RELACÃO COM OS CANAIS DE VENDA</h2>
          <ul>
            <li>
              Os Canais de Venda, conforme o caso, serão os responsáveis finais
              pelas atividades de disponibilização, comercialização,
              atendimento, faturamento e cobrança a Você.
            </li>
            <li>
              Na hipótese de suspensão, cancelamento ou término do Seu contrato
              com os Canais de Venda, Você perderá o acesso ao Conteúdo.
            </li>
          </ul>
        </div>
        <div className="usageListContainer">
          <h2>7. EXCLUSÃO DE GARANTIAS E DE RESPONSABILIDADES</h2>
          <ul>
            <li>
              Estamos em constante busca de melhoria para oferecer a Você a
              melhor experiência do Serviço. No entanto, o Serviço é
              disponibilizado no estado em que se encontra, sem nenhuma garantia
              de que sempre estará livre de erro ou de que funcionará sem
              interrupção, atraso ou imperfeição. Os anúncios de terceiros que
              eventualmente levem Você para fora do ambiente do Serviço não são
              de Nossa responsabilidade, assim como os produtos e/ou serviços
              anunciados, se e quando aplicável.
            </li>
            <li>
              Não nos responsabilizamos por períodos de interrupções por ocasião
              de eventos de manutenção, falhas na prestação de serviços das
              empresas de telefonia, de acesso condicionado ou de outras
              empresas de telecomunicações e dos provedores de acesso que
              inviabilizem a acessibilidade ao Conteúdo, nem por casos fortuitos
              e/ou motivos de força maior.
            </li>
            <li>
              Não nos responsabilizamos, em nenhuma hipótese, por prover a
              conexão banda larga a Você e pela qualidade de Sua conexão à
              Internet.
            </li>
            <li>
              Nós não garantimos a disponibilidade, infalibilidade e
              continuidade do funcionamento do Serviço, no todo ou em parte, nem
              que os mesmos serão úteis para a realização de qualquer atividade
              em particular.
            </li>
          </ul>
        </div>
        <div className="usageListContainer">
          <h2>8. ALTERAÇÕES DO SERVIÇO E DAS PRESENTES CONDIÇÕES GERAIS</h2>
          <ul>
            <li>
              Nos reservamos o direito de alterar quaisquer aspectos do Serviço,
              incluindo, porém não se limitando, a alterar qualquer Conteúdo,
              podendo incluir ou excluir características e funcionalidades em
              qualquer parte do mesmo, bem como suspender, cancelar ou
              descontinuar o Serviço, no todo ou em parte, temporariamente ou em
              definitivo, a qualquer momento, sem qualquer aviso prévio a Você.
            </li>
            <li>
              Podemos, ainda, alterar periodicamente os presentes Termos de Uso,
              a qualquer tempo, dando sempre prévio conhecimento a Você. Caso
              Você discorde de eventual alteração realizada, poderá se abster de
              utilizar e/ou cancelar a contratação do Serviço.
            </li>
            <li>
              Você compreende e concorda que a utilização do Serviço após
              qualquer alteração dos Termos de Uso será considerada como uma
              aceitação de tais alterações e concordância integral com os Termos
              de Uso.
            </li>
          </ul>
        </div>
        <div className="usageListContainer">
          <h2>9. SUSPENSÃO DOS SERVIÇOS E CANCELAMENTO DA ASSINATURA</h2>
          <ul>
            <li>
              Na hipótese de suspensão, cancelamento ou término do Seu contrato
              com os Canais de Venda, conforme o caso, Você perderá o acesso ao
              Conteúdo. Da mesma forma, caso constate-se, a qualquer tempo, que
              Você não é assinante do Serviço, seja por ter informado um código
              de assinatura de terceiro ou por qualquer outro motivo, poderemos
              cancelar seu acesso ao Serviço, sem necessidade de aviso prévio.
            </li>
            <li>
              Nos reservamos, ainda, o direito de bloquear o seu acesso, a
              qualquer momento, bem como acionar o respectivo Canal de Venda
              para que seja realizado o cancelamento da Assinatura, se Você: (i)
              infringir estes Termos de Uso, (ii) fizer um uso não autorizado do
              Serviço e/ou do Conteúdo, (iii) violar leis e regulamentos, (iv)
              compartilhar Sua senha, que é pessoal e intransferível.
            </li>
          </ul>
        </div>
        <div className="usageListContainer">
          <h2>
            10. PARA QUEM ESTES TERMOS DE USO PODERÃO SER CEDIDOS OU
            TRANSFERIDOS
          </h2>
          <ul>
            <li>
              Nós poderemos ceder ou transferir estes Termos de Uso para
              terceiros, sem necessidade de notificação prévia, inclusive em
              caso de fusão, aquisição, incorporação, reorganização societária
              e/ou venda de ativos, mantidos os demais termos e condições destes
              Termos de Uso.
            </li>
          </ul>
        </div>
        <div className="usageListContainer">
          <h2>11. DISPOSIÇÕES GERAIS</h2>
          <ul>
            <li>
              <strong>Comunicação:</strong> Caso Você considere que existam
              fatos ou circunstâncias que constituam ilicitude ou violação de
              quaisquer direitos na utilização do Serviço e/ou relativamente ao
              mesmo, deverá Nos enviar uma comunicação através do e-mail
              contato@sexprive.com.br. Nós enviaremos a Você quaisquer
              informações relacionadas a estes Termos de Uso em formato
              eletrônico, preferencialmente para o e-mail informado no momento
              de Seu cadastro, conforme atualizado de tempos em tempos, mas
              também poderemos notificá-lo através de mensagem de texto,
              aplicativo de mensagem e/ou notificação instantânea (push), caso
              essas opções estejam disponíveis.
            </li>
            <li>
              <strong>Independência das cláusulas:</strong> Se qualquer parte
              destes Termos de Uso for considerada inexequível, inválida ou
              ilegal, a parte restante permanecerá em total vigência e efeito.
            </li>
            <li>
              <strong>Não renúncia:</strong> Se qualquer cláusula destes Termos
              de Uso deixar de ser imposta, isto não será considerado uma
              renúncia ao direito pertinente.
            </li>
            <li>
              <strong>Lei Aplicável e Foro:</strong> Estes Termos de Uso serão
              regidos pela legislação brasileira. Qualquer reclamação ou
              controvérsia com base nestes Termos de Uso será dirimida
              exclusivamente pelo Foro Central da Comarca da Capital do Estado
              do Rio de Janeiro.
            </li>
            <li>
              <strong>Títulos:</strong> Os títulos das cláusulas destes Termos
              de Uso são meramente referenciais e não deverão ser interpretados
              de forma a restringir seu alcance e plena aplicabilidade.
            </li>
            <li>
              <strong>Sobrevivencia das disposições:</strong> As disposições
              acima sobreviverão ao cancelamento do Serviço, término, por
              qualquer motivo, destes Termos de Uso e/ou do contrato de
              assinante.
            </li>
          </ul>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default UsageTerms;
