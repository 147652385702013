import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import Axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import "./registerForm.css";
import { apiYouCast } from "../../sessions/apiYoucast";
import { apiCelCast } from "../../sessions/apiCelCast";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import { fetchAddressFromApi } from "../../sessions/apiViaCep";
import { v4 as uuidv4 } from "uuid";
import apiLocal from "../../sessions/apiLocal";
import apiBackendCSMS from "../../sessions/apiBackendCSMS";

function RegisterForm() {
  const navigate = useNavigate();
  const [steps, setSteps] = React.useState(0);
  const [formattedCPF, setFormattedCPF] = useState("");
  const addressForm = document.querySelector("#form");
  const cepInput = document.querySelector("#cep");
  const addressInput = document.querySelector("#address");
  const cityInput = document.querySelector("#city");
  const neighborhoodInput = document.querySelector("#neighborhood");
  const formInputs = document.querySelector("[data-input]");

  const [selectedPlan, setSelectedPlan] = useState(null);

  const availablePlans = [
    {
      id: "1",
      name: "Mensal",
      price: "4,90",
    },
  ];

  const offerID = localStorage.getItem("selectedOfferID");

  const handlePlanChange = (event) => {
    const selectedPlanID = event.target.value;
    const selectedPlan = availablePlans.find(
      (plan) => plan.id === selectedPlanID
    );
    setSelectedPlan(selectedPlan);
  };

  useEffect(() => {
    if (offerID) {
      const selectedPlan = availablePlans.find((plan) => plan.id === offerID);
      if (selectedPlan) {
        setSelectedPlan(selectedPlan);
      }
    }
  }, []);

  const stepsText = [
    {
      id: "STEP1",
      title: "Informe seus dados",
    },
    {
      id: "STEP2",
      title: "Informe seus dados de endereço",
    },
  ];

  const [formValues, setFormValues] = useState({
    selectedPayment: "",
    name: "",
    email: "",
    phone: "",
    cpf: "",
    password: "",
    confirmPassword: "",
    cep: "",
    address: "",
    number: "",
    complement: "",
    state: "",
    city: "",
    neighborhood: "",
    is_active: false,
  });

  const [errors, setErrors] = useState({
    selectedPayment: false,
    name: false,
    cpf: false,
    phone: false,
    email: false,
    password: false,
    confirmPassword: false,
    cep: false,
    address: false,
    number: false,
    complement: false,
    state: false,
    city: false,
    neighborhood: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let formattedValue = value;
    let validationError = false;

    if (name == "name") {
      validationError = !isValidName(value);
    } else if (name === "cpf") {
      validationError = !isValidCPF(value);
      formattedValue = formatCPF(value);
    } else if (name === "phone") {
      validationError = !isValidPhoneNumber(value);
      formattedValue = formatPhoneNumber(value);
    } else if (name === "email") {
      validationError = !isValidEmail(value);
    } else if (name === "confirmPassword") {
      validationError = value !== formValues.password;
    } else if (name === "cep") {
      formattedValue = formatCEP(value);
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: formattedValue,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validationError,
    }));
  };

  function isValidCPF(cpf) {
    const cleanedCPF = cpf.replace(/\D/g, "");

    if (/^(\d)\1+$/.test(cleanedCPF)) {
      return false;
    }

    const cpfArray = cleanedCPF.split("").map(Number);
    const [a, b, c, d, e, f, g, h, i] = cpfArray;
    const sum1 =
      10 * a + 9 * b + 8 * c + 7 * d + 6 * e + 5 * f + 4 * g + 3 * h + 2 * i;
    const remainder1 = (sum1 * 10) % 11;
    const digit1 = remainder1 === 10 || remainder1 === 11 ? 0 : remainder1;

    const sum2 =
      11 * a +
      10 * b +
      9 * c +
      8 * d +
      7 * e +
      6 * f +
      5 * g +
      4 * h +
      3 * i +
      2 * digit1;
    const remainder2 = (sum2 * 10) % 11;
    const digit2 = remainder2 === 10 || remainder2 === 11 ? 0 : remainder2;

    const isValid = digit1 === cpfArray[9] && digit2 === cpfArray[10];
    return isValid;
  }

  function isValidPhoneNumber(phoneNumber) {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

    if (/^(\d)\1+$/.test(cleanedPhoneNumber)) {
      return false;
    }

    const phoneRegex = /^(\d{2})?(\d{4,5})(\d{4})$/;
    const isValid = phoneRegex.test(cleanedPhoneNumber);

    return isValid;
  }

  function isValidPassword(password) {
    // A senha deve ter pelo menos 8 caracteres, uma letra maiúscula e um caractere especial.
    return (
      password &&
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)
    );
  }

  function isValidName(name) {
    // Verifica se o nome contém apenas letras (maiúsculas e minúsculas) e espaços.
    return /^[A-Za-z\s]+$/.test(name);
  }

  function isValidEmail(email) {
    const trimmedEmail = email.trim();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(trimmedEmail)) {
      return false;
    }

    const [, domain] = trimmedEmail.split("@");

    const domainRegex = /^[A-Za-z0-9.-]+\.[A-Z]{2,}$/i;

    return domainRegex.test(domain);
  }

  function arePasswordsEqual(password, confirmPassword) {
    return password === confirmPassword;
  }

  const [currentStep, setCurrentStep] = useState(0);

  function handleNextStep() {
    return new Promise((resolve, reject) => {
      let requiredFields = [];

      switch (currentStep) {
        case 0:
          requiredFields = [
            "selectedPayment",
            "name",
            "cpf",
            "phone",
            "email",
            "password",
          ];
          break;
        case 1:
          requiredFields = [
            "cep",
            "address",
            "number",
            "state",
            "city",
            "neighborhood",
          ];
          break;
        default:
          break;
      }

      const hasEmptyFields = requiredFields.some((field) => !formValues[field]);

      const isNameValid = isValidName(formValues.name);
      const isCPFValid = isValidCPF(formValues.cpf);
      const isPhoneValid = isValidPhoneNumber(formValues.phone);
      const isEmailValid = isValidEmail(formValues.email);
      const isConfirmPasswordValid =
        formValues.password === formValues.confirmPassword;
      const isPasswordValid = isValidPassword(formValues.password);

      if (
        hasEmptyFields ||
        !isNameValid ||
        !isCPFValid ||
        !isPhoneValid ||
        !isEmailValid ||
        !isConfirmPasswordValid ||
        !isPasswordValid
      ) {
        reject("Os campos não foram preenchidos corretamente.");
        return;
      }

      // console.log(" PAYMENT TA MUDANDOO --> ", formValues.selectedPayment);

      if (currentStep === 0) {
        isExistEmail(formValues.email)
          .then(({ userExists }) => {
            // console.log("ISEXIST--> ", { userExists });
            if (!userExists) {
              setCurrentStep((prevState) => prevState + 1);
              resolve("Próxima etapa permitida.");
            } else {
              reject("Email já existe.");
            }
          })
          .catch((error) => {
            console.error("Erro na verificação de email:", error);
            reject("Erro na verificação de email.");
          });
      }

      if (currentStep === 1 && !isAddressValid) {
        reject("Endereço inválido.");
      }
    });
  }

  function handlPreviousStep() {
    setCurrentStep((prevState) => prevState - 1);
  }

  const formatCPF = (cpf) => {
    const cleanedCPF = cpf.replace(/\D/g, "");

    const formattedCpf = cleanedCPF.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      "$1.$2.$3-$4"
    );

    return formattedCpf;
  };

  function formatPhoneNumber(input) {
    const cleaned = input.replace(/\D/g, "");

    const formattedPhone = cleaned.replace(
      /(\d{2})(\d{5})(\d{4})/,
      "($1) $2-$3"
    );
    return formattedPhone;
  }

  const formatCEP = (cep) => {
    const cleanedCEP = cep.replace(/\D/g, "");
    const formattedCEP = cleanedCEP.replace(/(\d{5})(\d{3})/, "$1-$2");

    return formattedCEP;
  };

  function clean_form_cep() {
    document.getElementById("address").value = "";
    document.getElementById("state").value = "";
    document.getElementById("city").value = "";
    document.getElementById("neighborhood").value = "";
  }

  const [isAddressValid, setIsAddressValid] = useState(false);

  function isValidCEP(cep) {
    const cleanedCEP = cep.replace(/\D/g, "");
    return /^[0-9]{8}$/.test(cleanedCEP);
  }

  const searchcep = async (value) => {
    const cep = value.replace(/\D/g, "");

    if (isValidCEP(cep)) {
      try {
        setFormValues((prevValues) => ({
          ...prevValues,
          address: "...",
          state: "...",
          city: "...",
          neighborhood: "...",
        }));

        const addressData = await fetchAddressFromApi(cep);

        if (addressData) {
          setFormValues((prevValues) => ({
            ...prevValues,
            address: addressData.logradouro,
            state: addressData.uf,
            city: addressData.localidade,
            neighborhood: addressData.bairro,
          }));

          setIsAddressValid(true);
        } else {
          clean_form_cep();
          setIsAddressValid(false);
        }
      } catch (error) {
        clean_form_cep();
        setIsAddressValid(false);
        alert(error.message);
      }
    } else {
      clean_form_cep();
      setIsAddressValid(false);
      toast.error("Formato de CEP inválido.");
    }
  };

  const [loading, setLoading] = useState(false);

  const handleButtonClick = async () => {
    try {
      setLoading(true);
      handleSubmit();
      await handleNextStep();
      window.scrollTo(0, 0);
    } catch (error) {
      console.error("Erro no handleNextStep:", error);
    } finally {
      setLoading(false);
    }
  };

  function isExistEmail(email) {
    return new Promise(async (resolve, reject) => {
      try {
        try {
          const username = process.env.REACT_APP_USERNAME_CEL_CASH;
          const password = process.env.REACT_APP_PASSWORD_CEL_CASH;
          const basicAuth = btoa(`${username}:${password}`);
          const baseURL = process.env.REACT_APP_API_CEL_CASH_URL;
          const endpoint = "/token";
          const requestBody = {
            grant_type: "authorization_code",
            scope:
              "customers.read customers.write plans.read plans.write transactions.read transactions.write webhooks.write balance.read balance.write cards.read cards.write card-brands.read subscriptions.read subscriptions.write charges.read charges.write boletos.read",
          };
          const response = await Axios.post(
            `${baseURL}${endpoint}`,
            requestBody,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Basic ${basicAuth}`,
              },
            }
          );

          const responseData = response.data;
          localStorage.setItem("access_token", responseData.access_token);
        } catch (tokenError) {
          console.error("Erro na chamada à API do token:", tokenError);
          reject(new Error("Erro na requisição do token"));
          return;
        }

        //VER SE PRECISO MUDAR CHAMADA NO BACK
        apiYouCast.get(`getCurrentUser/${email}`).then(async (response) => {
          const userWithEmail = response.data;

          console.log("userwithEmail--> ", userWithEmail);

          console.log("TO AQUI ANTES DA VERIFICAÇÃO DO STTS");

          if (
            userWithEmail.statusCelCash ===
            "user not plan in the CelCash system"
          ) {
            console.log("TO AQUI NO PRIMEIRO IF");
            resolve({
              userExists: false,
            });
          } else if (userWithEmail.statusCelCash === "active") {
            console.log("TO AQUI NO SEGUNDO IF NO ACTIVE");
            toast.error(
              "Você já possui conta ativa na nossa plataforma! Vamos lhe redirecionar para nossa plataforma!"
            );
            setTimeout(() => {
              window.location.href = "https://sexpriveplay.com.br/login";
            }, 3000);
          } else if (userWithEmail.statusCelCash === "waitingPayment") {
            (async () => {
              try {
                const token = localStorage.getItem("access_token");

                const subscriptionResponse = await apiCelCast.get(
                  `/subscriptions?myIds=${userWithEmail.id}&startAt=0&limit=100`,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );

                const subscriptions =
                  subscriptionResponse.data.Subscriptions || [];

                if (subscriptions.length > 0) {
                  console.log("TO AQUI NO N SEIII");
                  const delayedSubscription = subscriptions[0];

                  const paymentLink = delayedSubscription.paymentLink;
                  localStorage.setItem(
                    "@payment-link",
                    JSON.stringify(paymentLink)
                  );
                  toast.success(
                    "Você já possui cadastro na nossa plataforma! Vamos lhe redirecionar para o pagamento!"
                  );
                  setTimeout(() => {
                    window.location.href = paymentLink;
                  }, 3000);
                }

                resolve({ userExists: true });
              } catch (subscriptionError) {
                console.error(
                  "Erro na requisição de assinaturas:",
                  subscriptionError
                );
                reject(new Error("Erro na requisição de assinaturas"));
              }
            })();
          } else if (
            userWithEmail.statusCelCash === "inactive" ||
            userWithEmail.statusCelCash === "canceled"
          ) {
            console.log("TO AQUI NO CANCELED");
            const formData = JSON.parse(localStorage.getItem("@data-user"));
            console.log("AQUIII-->", formData);

            const token = localStorage.getItem("access_token");
            const myId = uuidv4();
            const currentDate = new Date();
            const formattedDate = `${currentDate.getFullYear()}-${(
              currentDate.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}-${currentDate
              .getDate()
              .toString()
              .padStart(2, "0")}`;

            const userDataWithEmail = {
              name: userWithEmail.name,
              cpf: userWithEmail.cpf,
              phone: userWithEmail.phone,
              email: userWithEmail.email,
            };

            const removeNonNumericCharacters = (value) => {
              if (value) {
                return value.replace(/\D/g, "");
              }
              return "";
            };

            const cleanFormData = {
              ...userDataWithEmail,
              cpf: removeNonNumericCharacters(userDataWithEmail.cpf),
              phone: removeNonNumericCharacters(userDataWithEmail.phone),
            };

            const commonData = {
              // myId: myId,
              // planMyId: offerID,
              // planGalaxPayId: parseInt(offerID, 10),
              // firstPayDayDate: formattedDate,
              // mainPaymentMethodId: "creditcard",
              // Customer: {
              //   myId: myId,
              //   name: userDataWithEmail.name,
              //   document: cleanFormData.cpf,
              //   emails: [userDataWithEmail.email],
              //   phones: [parseInt(cleanFormData.phone, 10)],
              //   PaymentMethodCreditCard: {
              //     Card: {},
              //   },
              //   PaymentMethodPix: {
              //     Deadline: {},
              //     instructions: " ",
              //   },
              // },

              myId: myId,
              value: 500, // Valor de 5.00
              quantity: 0, // Quantidade de cobranças indeterminado
              periodicity: "monthly",
              firstPayDayDate: formattedDate,
              additionalInfo: " Lorem ipsum dolor sit amet.",
              mainPaymentMethodId: formData.selectedPayment,
              Customer: {
                myId: myId,
                name: userDataWithEmail.name,
                document: cleanFormData.cpf,
                emails: [userDataWithEmail.email],
                phones: [parseInt(cleanFormData.phone, 10)],
              },
              PaymentMethodCreditCard: {
                Card: {},
              },
              PaymentMethodPix: {
                instructions: "Serviço de conteúdo de streaming",
                Deadline: {},
              },
            };

            console.log("DataUser", commonData);

            const putNewId = "/putNewId";
            const newIdRequestBody = {
              newId: myId,
              email: userDataWithEmail.email,
            };
            apiYouCast
              .put(putNewId, newIdRequestBody)
              .then((putResponse) => {
                apiCelCast
                  .post("/subscriptions", commonData, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then((response) => {
                    const paymentLink = response.data.Subscription.paymentLink;
                    localStorage.setItem(
                      "@payment-link",
                      JSON.stringify(paymentLink)
                    );
                    toast.success(
                      "Você já possui cadastro na nossa plataforma! Vamos lhe redirecionar para o pagamento!"
                    );
                    setTimeout(() => {
                      window.location.href = paymentLink;
                    }, 3000);
                    resolve({ userExists: true });
                  });
              })
              .catch((e) => {
                toast.error(
                  "Usuário acabou de cancelar a assinatura, tente novamente mais tarde"
                );
                reject();
              });
          }
        });
      } catch (error) {
        console.error("Erro inesperado:", error);
        reject(new Error("Erro na requisição"));
      }
    });
  }

  function removeNonNumericCharacters(value) {
    if (value) {
      return value.replace(/\D/g, "");
    }
    return "";
  }

  const handleSubmit = async () => {
    let requiredFields = [];

    switch (currentStep) {
      case 0:
        requiredFields = [
          "selectedPayment",
          "name",
          "cpf",
          "phone",
          "email",
          "password",
        ];
        break;
      case 1:
        requiredFields = [
          "cep",
          "address",
          "number",
          "state",
          "city",
          "neighborhood",
        ];
        break;
      default:
        break;
    }

    // console.log("SUBMIT--->", formValues.selectedPayment);
    localStorage.setItem("@data-user", JSON.stringify(formValues));
    const formData = JSON.parse(localStorage.getItem("@data-user"));
    // console.log("AQUIII-->", formData);

    const hasEmptyFields = requiredFields.some((field) => !formValues[field]);

    const isConfirmPasswordValid =
      formValues.password === formValues.confirmPassword;
    const isPasswordValid = isValidPassword(formValues.password);
    const isCPFValid = isValidCPF(formValues.cpf);
    const isNameValid = isValidName(formValues.name);
    const isPhoneValid = isValidPhoneNumber(formValues.phone);
    const isEmailValid = isValidEmail(formValues.email);

    const alertMessages = {
      emptyFields:
        "Preencha todos os campos obrigatórios antes de enviar o formulário.",
      invalidCPF: "CPF inválido!",
      invalidName:
        "Nome de usuário não pode conter números ou caracteres especiais",
      invalidPhone: "Telefone inválido!",
      invalidEmail: "Email inválido!",
      invalidCPFPhone: "CPF e Telefone inválidos.", //foi

      invalidCPFPhoneName: "Nome, CPF Telefone inválidos.", //foi

      invalidCPFEmail: "CPF e Email inválidos.", //foi
      invalidCPFEmailName: "Nome, CPF e Email inválidos.", //foi

      invalidPhoneEmail: "Telefone e Email inválidos.", //foi
      invalidPhoneEmailName: "Nome, Telefone e Email inválidos.", //foi

      invalidCPFPhoneEmail: "CPF, Telefone e Email inválidos.", //foi
      invalidCPFPhoneEmailName: "Nome, CPF, Telefone e Email inválidos.", //foi

      invalidCPFName: "CPF e Nome inválidos",
      invalidPhoneName: "Telefone e Nome inválidos",
      invalidEmailName: "Email e Nome inválidos",

      passwordMismatch: "As senhas não coincidem.",
      invalidAddress: "Digite um endereço válido.",
      invalidPassword:
        "A senha deve ter pelo menos 8 caracteres, uma letra maiúscula e um caractere especial.",
    };

    // console.log("TEM CAMPO VAZIO?-->", hasEmptyFields);
    if (hasEmptyFields) {
      toast.error(alertMessages.emptyFields);
    } 
    else if (!isNameValid && !isCPFValid && !isPhoneValid && !isEmailValid) {
      toast.error(alertMessages.invalidCPFPhoneEmailName);
    } 
    
    
    else if (!isCPFValid && !isPhoneValid && !isEmailValid) {
      toast.error(alertMessages.invalidCPFPhoneEmail);
    } 
    
    
    else if (!isNameValid && !isCPFValid && !isPhoneValid) {
      toast.error(alertMessages.invalidCPFPhoneName);
    } 
  
    
    
    else if (!isCPFValid && !isPhoneValid) {
      toast.error(alertMessages.invalidCPFPhone);
    } 
    
    else if (!isNameValid && !isCPFValid && !isEmailValid) {
      toast.error(alertMessages.invalidCPFEmailName);
    } 
    

    
    
    else if (!isCPFValid && !isEmailValid) {
      toast.error(alertMessages.invalidCPFEmail);
    } 
    
    
    else if (!isNameValid && !isPhoneValid && !isEmailValid) {
      toast.error(alertMessages.invalidPhoneEmailName);
    }
    else if (!isNameValid && !isPhoneValid) {
      toast.error(alertMessages.invalidPhoneName);
    }    
     else if (!isNameValid && !isEmailValid) {
      toast.error(alertMessages.invalidEmailName);
    } 
    else if (!isNameValid && !isCPFValid ) {
      toast.error(alertMessages.invalidCPFName);
    }
    
    
    else if (!isPhoneValid && !isEmailValid) {
      toast.error(alertMessages.invalidPhoneEmail);
    } else if (!isNameValid) {
      toast.error(alertMessages.invalidName);
    } else if (!isCPFValid) {
      toast.error(alertMessages.invalidCPF);
    } else if (!isPhoneValid) {
      toast.error(alertMessages.invalidPhone);
    } else if (!isEmailValid) {
      toast.error(alertMessages.invalidEmail);
    } else if (!isConfirmPasswordValid) {
      toast.error(alertMessages.passwordMismatch);
    } else if (!isPasswordValid) {
      toast.error(alertMessages.invalidPassword);
    } else if (currentStep === 1) {
      if (!isAddressValid) {
        toast.error(alertMessages.invalidAddress);
      } else {
        const loginData = {
          login: formValues.email,
          password: formValues.password,
        };

        // try {
        //   const CryptoJS = require("crypto-js");
        //   const secretKey = process.env.REACT_APP_SECRET_KEY;
        //   if (!secretKey) {
        //     console.error("A chave secreta não está definida no arquivo .env.");
        //     process.exit(1);
        //   }
        //   const encryptedPassword = CryptoJS.AES.encrypt(
        //     formValues.password,
        //     secretKey
        //   ).toString();

        //   // Não criptografe a cidade e o estado, apenas adicione-os ao cookie
        //   const cookieCity = formValues.city;
        //   const cookieState = formValues.state;

        //   document.cookie = `loginData=${encodeURIComponent(
        //     formValues.email
        //   )}|${encodeURIComponent(encryptedPassword)}|CITY=${encodeURIComponent(
        //     cookieCity
        //   )}|STATE=${encodeURIComponent(cookieState)}|; path=/`;
        // } catch (error) {
        //   console.error("Erro ao criptografar a senha:", error);
        // }

        localStorage.clear();
        localStorage.setItem("@data-user", JSON.stringify(formValues));

        const performAPICall = async () => {
          const username = process.env.REACT_APP_USERNAME_CEL_CASH;
          const password = process.env.REACT_APP_PASSWORD_CEL_CASH;

          const basicAuth = btoa(`${username}:${password}`);
          const baseURL = process.env.REACT_APP_API_CEL_CASH_URL;
          const endpoint = "/token";

          const requestBody = {
            grant_type: "authorization_code",
            scope:
              "customers.read customers.write plans.read plans.write transactions.read transactions.write webhooks.write balance.read balance.write cards.read cards.write card-brands.read subscriptions.read subscriptions.write charges.read charges.write boletos.read",
          };

          try {
            const response = await Axios.post(
              `${baseURL}${endpoint}`,
              requestBody,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Basic ${basicAuth}`,
                },
              }
            );

            const responseData = response.data;
            localStorage.setItem("access_token", responseData.access_token);
          } catch (error) {
            console.error("Erro na chamada à API do token:", error);
          }
        };

        const removeNonNumericCharacters = (value) => {
          if (value) {
            return value.replace(/\D/g, "");
          }
          return "";
        };

        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${(
          currentDate.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${currentDate
          .getDate()
          .toString()
          .padStart(2, "0")}`;

        const formData = JSON.parse(localStorage.getItem("@data-user"));
        console.log("AQUIII-->", formData);

        try {
          const responseYouCast = await apiYouCast.post(
            "createdUser",
            formValues
          );
          const userId = responseYouCast.data.id;

          localStorage.setItem("@id-user", JSON.stringify(userId));
          setCurrentStep(2);

          const myId = JSON.parse(localStorage.getItem("@id-user"));

          // Armazene os dados criptografados em algum lugar seguro para uso posterior
          const bodyDataInsertCSMS = {
            data: {
              login: formValues.email, // Aqui usamos o email como login, ajuste conforme necessário
              password: formValues.password,
              firstname: formValues.city,
              lastname: formValues.state,
            },
          };

          // Agora você pode enviar 'encryptedData' como corpo (body) em uma chamada de API posteriormente
          console.log("Dados body:", bodyDataInsertCSMS);

          const csmsResponse = await apiBackendCSMS.post(
            "insertUserCSMS",
            bodyDataInsertCSMS
          );
          console.log("DADOS NO CSMS, ", csmsResponse);

          const cleanFormData = {
            ...formData,
            cep: removeNonNumericCharacters(formData.cep),
            cpf: removeNonNumericCharacters(formData.cpf),
            phone: removeNonNumericCharacters(formData.phone),
          };

          await performAPICall(cleanFormData);

          const token = localStorage.getItem("access_token");

          const commonData = {
            // myId: myId,
            // planMyId: offerID,
            // planGalaxPayId: parseInt(offerID, 10),
            // firstPayDayDate: formattedDate,
            // mainPaymentMethodId: formData.selectedPayment,
            // Customer: {
            //   myId: myId,
            //   name: formData.name,
            //   document: cleanFormData.cpf,
            //   emails: [formData.email],
            //   phones: [parseInt(cleanFormData.phone, 10)],
            //   Address: {
            //     zipCode: cleanFormData.cep,
            //     street: formData.address,
            //     number: formData.number,
            //     complement: formData.complement,
            //     neighborhood: formData.neighborhood,
            //     city: formData.city,
            //     state: formData.state,
            //   },
            // },
            // PaymentMethodCreditCard: {
            //   Card: {},
            // },
            // PaymentMethodPix: {
            //   Deadline: {},
            //   instructions: " ",
            // },
            // InvoiceConfig: {
            //   description: "Serviço de conteúdo de streaming",
            //   smu: 102178,
            //   type: "onePerTransaction",
            //   createOn: "payment",
            // },

            myId: myId,
            value: 500, // Valor de 5.00
            quantity: 0, // Quantidade de cobranças indeterminada
            periodicity: "monthly",
            firstPayDayDate: formattedDate,
            additionalInfo: " Lorem ipsum dolor sit amet.",
            mainPaymentMethodId: formData.selectedPayment,
            Customer: {
              myId: myId,
              name: formData.name,
              document: cleanFormData.cpf,
              emails: [formData.email],
              phones: [parseInt(cleanFormData.phone, 10)],
              Address: {
                zipCode: cleanFormData.cep,
                street: formData.address,
                number: formData.number,
                complement: formData.complement,
                neighborhood: formData.neighborhood,
                city: formData.city,
                state: formData.state,
              },
            },

            PaymentMethodCreditCard: {
              Card: {},
            },
            PaymentMethodPix: {
              instructions: "Serviço de conteúdo de streaming",
              Deadline: {},
            },
            InvoiceConfig: {
              description: "Serviço de conteúdo de streaming",
              type: "onePerTransaction",
              createOn: "payment",
            },
          };

          commonData.Customer.galaxPayId = parseInt(
            commonData.Customer.galaxPayId,
            10
          );

          let paymentLink;
          // console.log("tresata", commonData);
          try {
            const response = await apiCelCast.post(
              "/subscriptions",
              commonData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            // console.log("DATA API CELCASH POST ", response.data);
            // console.log(
            //   "method ",
            //   response.data.Subscription.mainPaymentMethodId
            // );
            const methodPayment =
              response.data.Subscription.mainPaymentMethodId;
            const transactions = response.data.Subscription.Transactions;

            if (
              methodPayment === "pix" &&
              transactions &&
              transactions.length > 0
            ) {
              const pixTransaction = transactions[0].Pix;
              const value = transactions[0].value;

              if (pixTransaction) {
                const imgPix = pixTransaction.image;
                const qrCode = pixTransaction.qrCode;

                localStorage.setItem("@imgPix", JSON.stringify(imgPix));
                localStorage.setItem("@qrCode", JSON.stringify(qrCode));
                localStorage.setItem("@value", JSON.stringify(value));

                // Redirecionar para a página de pagamento PIX
                navigate("/paymentPix");
              } else {
                console.error(
                  "Erro: Transação PIX não encontrada na resposta da API."
                );
                // Lógica de tratamento de erro, se necessário
              }
            } else {
              paymentLink = response.data.Subscription.paymentLink;
              localStorage.setItem(
                "@payment-link",
                JSON.stringify(paymentLink)
              );

              if (
                offerID === "1" ||
                offerID === "2" ||
                offerID === "3" ||
                offerID === "4"
              ) {
                window.location.href = paymentLink;
              }
            }
          } catch (error) {
            console.error("Erro na chamada POST:", error);
            toast.error("CPF usado por outro cliente ou Telefone inválido!");

            if (error.response) {
              console.error("Status do erro:", error.response.status);
              console.log("Detalhes do erro:", error.response.data);
            } else if (error.request) {
              console.error("Sem resposta do servidor");
            } else {
              console.error(
                "Erro ao configurar a solicitação ou ao lidar com a resposta:",
                error.message
              );
            }

            return;
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  return (
    <div className="registerFormContainer">
      <div className="stepContainer">
        <div className="stepStyleContainer">
          <div
            className={
              stepsText[currentStep] && stepsText[currentStep].id !== "STEP1"
                ? "stepCircle stepCompletedColor"
                : "stepCircle stepIncompleteColor"
            }
          >
            {stepsText[currentStep] && stepsText[currentStep].id !== "STEP1" ? (
              <img src={require("../../assets/check.png")} alt="Checkmark" />
            ) : (
              <p>1</p>
            )}
          </div>

          <div
            className={
              stepsText[currentStep] && stepsText[currentStep].id !== "STEP1"
                ? "stepLine stepCompletedColor"
                : "stepLine stepIncompleteColor"
            }
          ></div>

          <div
            className={
              stepsText[currentStep] &&
              stepsText[currentStep].id !== "STEP1" &&
              stepsText[currentStep].id !== "STEP2"
                ? "stepCircle stepCompletedColor"
                : "stepCircle stepIncompleteColor"
            }
          >
            {stepsText[currentStep] &&
            stepsText[currentStep].id !== "STEP1" &&
            stepsText[currentStep].id !== "STEP2" ? (
              <img src={require("../../assets/check.png")} alt="Checkmark" />
            ) : (
              <p>2</p>
            )}
          </div>
        </div>

        <div className="stepText">
          {currentStep <= 1 && (
            <h1>
              Passo {currentStep + 1}:{" "}
              {stepsText[currentStep] && stepsText[currentStep].title}
            </h1>
          )}
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        {stepsText[currentStep] && stepsText[currentStep].id == "STEP1" && (
          <Step1
            selectedPlan={selectedPlan}
            handlePlanChange={handlePlanChange}
            availablePlans={availablePlans}
            handleInputChange={handleInputChange}
            formValues={formValues}
          />
        )}

        {stepsText[currentStep] && stepsText[currentStep].id === "STEP2" && (
          <Step2
            searchcep={searchcep}
            handleInputChange={handleInputChange}
            formValues={formValues}
          />
        )}
      </form>
      <Toaster />

      <div className="flexButtons">
        {currentStep > 0 && currentStep < 2 && (
          <button
            className="button next"
            type="button"
            onClick={() => {
              handlPreviousStep();
              window.scrollTo(0, 0);
            }}
          >
            Anterior
          </button>
        )}

        {loading && (
          <div className="overlay">
            <span className="loading-spinner-fullscreen"></span>
          </div>
        )}
        <button
          className={`button next ${loading ? "loading" : ""}`}
          type="button"
          onClick={handleButtonClick}
        >
          Próximo
        </button>
      </div>
    </div>
  );
}

export default RegisterForm;
