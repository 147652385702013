import axios from "axios";

const username = String(process.env.REACT_APP_USERNAME_CEL_CASH);
const password = String(process.env.REACT_APP_PASSWORD_CEL_CASH);

export const apiCelCast = axios.create({
  baseURL: process.env.REACT_APP_API_CEL_CASH_URL,
  timeout: 15000,
  headers: {
    Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    "Content-Type": "application/json",
  },
});

const requestBody = {
  grant_type: "authorization_code",
  scope:
    "customers.read customers.write plans.read plans.write transactions.read transactions.write webhooks.write balance.read balance.write cards.read cards.write card-brands.read subscriptions.read subscriptions.write charges.read charges.write boletos.read",
};

apiCelCast
  .post("/token", requestBody)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    console.error(error);
  });
