function formatDate(date) {
  const dataCriada = new Date(date.toString());
  const formatedDate = new Intl.DateTimeFormat("pt-BR", {
    dateStyle: "full",
    timeStyle: "short",
  }).format(dataCriada);

  return formatedDate;
}

export default formatDate;
