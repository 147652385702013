import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { useNavigate } from "react-router-dom";

import api from "../../sessions/api";

import shaka from "shaka-player";
import ShakaPlayer from "shaka-player-react";

import formatDuration from "../../utils/formatDuration";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./index.css";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

import { animateScroll as scroll, scroller } from "react-scroll";

function formatTitleLength(title) {
  const formatedTitle =
    title.length <= 15 ? title : title.substring(0, 15) + "...";

  return formatedTitle;
}

function checkType(type) {

  if(type === "card") {
    return 1
  } else {
    return 2
  }
}

function showContentRows(data, type) {
  const scrollToOfferContainer = () => {
    scroller.scrollTo("offerTitle", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return data.values.data.map((item, idx) => {
    return (
      <SwiperSlide key={idx} className={"swiperCard"+ checkType(type)}>
        <button
          className={"swiperButton"+ checkType(type)}
          onMouseEnter={() => {
            //getStreamUrl(item.type, item.id);
          }}
          onClick={() => {
            scrollToOfferContainer();
            window.scrollTo(0, 0);
          }}
        >
          <div className="contentImage">
            <img src={item.image} style={{
              objectFit: data.values.title === "Assista ao Vivo" 
              ? "contain" 
              : data.values.title === "Canais" 
              ? "contain" 
              : "" }}></img>
            
            {data.values.title === "Canais" ? "" : 
            <div className="gradientColorCard">
              <div className="gradientColorCardLogo">
                <img src={require("../../assets/sexprive_logo.png")} alt="Logo"></img>
              <hr size="20"></hr>
              </div>


              <div className="gradientColorCardText">
                <h4>Original</h4>
              </div>
            </div>

            }

          </div>

          {data.values.title === "Canais" ? "" : 
          <div className="contentDescription">
            <h3>{item.title == null ? " " : formatTitleLength(item.title)}</h3>

            <div className="contentDuration">
              <img src={require("../../assets/clock.png")}></img>
              <h3>{formatDuration(item.duration)}M</h3>
            </div>

          </div>
          }



        </button>
      </SwiperSlide>
    );
  })
}

export function Cards(data) {
  const navigate = useNavigate();
  const [slidePerView, setSliderPerView] = useState(5);
  const [videoResponse, setVideoResponse] = useState("");
  const [src, setSrc] = React.useState("");

  const [myEdges, setMyEdges] = useState(0);
  const [myOffset, setMyOffset] = useState("");
  const [myUrl, setMyUrl] = useState("");

  /* 
  function getStreamUrl(type, id) {
    setVideoLoaded(false);
    const response = api
      .post("/getPreviewUrlInfo", {
        type: type,
        id: id,
      })
      .then(async function (response) {
        if (response.data.status == 1) {
          setVideoResponse(response);
          setMyOffset(response.data.response.offset);
          setMyEdges(response.data.response.edgesId);
          setMyUrl(response.data.response.url);
          setVideoLoaded(true);

          shaka.polyfill.installAll();
          var video = document.getElementById("video");
          var player = new shaka.Player(video);

          player.configure({
            drm: {
              servers: {
                "com.widevine.alpha": "https://mw.yplay.com.br/widevine_proxy",
              },
            },
          });

          var devicesType = "web player";
          var devices_identification = "Windows 10 Chrome 118";
          var devices_hash = process.env.REACT_APP_YOUCAST_DEVICES_HASH;
          var customers_token = process.env.REACT_APP_CUSTOMERS_TOKEN;
          var profilesId = process.env.REACT_APP_YOUCAST_PROFILES_ID;
          var version = "1.0.12";
          var timestamp = parseInt(new Date().getTime() / 1000);

          player
            .getNetworkingEngine()
            .registerRequestFilter(function (type, request) {
              const asd = "a";

              return type;
            });

          player
            .getNetworkingEngine()
            .registerRequestFilter(function (type, request) {
              var StringUtils = shaka.util.StringUtils;
              var Uint8ArrayUtils = shaka.util.Uint8ArrayUtils;

              if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
                request.headers["Authorization"] = "Bearer " + customers_token;
                request.headers["profilesId"] = btoa(profilesId);
                request.headers["devicesType"] = btoa(devicesType);
                request.headers["version"] = btoa(version);
                request.headers["browserType"] = btoa("chrome");
                var wrapped = {};
                wrapped.timestamp = timestamp;
                wrapped.offset = myOffset;
                wrapped.edges_id = myEdges;
                wrapped.devices_identification = devices_identification;
                wrapped.devices_hash = devices_hash;
                wrapped.rawLicense = Uint8ArrayUtils.toBase64(
                  new Uint8Array(request.body),
                  false
                );

                var wrappedJson = JSON.stringify(wrapped);
                request.body = StringUtils.toUTF8(wrappedJson);
              }
            });
          player
            .getNetworkingEngine()
            .registerResponseFilter(function (type, response) {
              var StringUtils = shaka.util.StringUtils;
              var Uint8ArrayUtils = shaka.util.Uint8ArrayUtils;

              if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
                var wrappedString = StringUtils.fromUTF8(response.data);

                var wrapped = JSON.parse(wrappedString);
                var rawLicense = wrapped.rawLicense;
                response.data = Uint8ArrayUtils.fromBase64(rawLicense);
              }
            });
        }
      })
      .catch(function (response) {
        console.log("o response de erro", response);
      });
  }*/

  useEffect(() => {
    function handleResize() {

        if (window.innerWidth > 1281) {
          setSliderPerView(5);
        } else if (window.innerWidth > 1025 && window.innerWidth < 1280) {
          setSliderPerView(4);
        } else if (window.innerWidth > 600 && window.innerWidth < 1024) {
          setSliderPerView(3);
        } else if (window.innerWidth > 480 && window.innerWidth < 599) {
          setSliderPerView(3);
        } else if (window.innerWidth < 479) {
          setSliderPerView(2);
        }

    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

    function handleResize2() {

        if (window.innerWidth > 1281) {
          setSliderPerViewStyled(4)
        } else if (window.innerWidth > 1025 && window.innerWidth < 1280) {
          setSliderPerViewStyled(3);
        } else if (window.innerWidth > 600 && window.innerWidth < 1024) {
          setSliderPerViewStyled(3);
        } else if (window.innerWidth > 480 && window.innerWidth < 599) {
          setSliderPerViewStyled(3);
        } else if (window.innerWidth < 479) {
          setSliderPerViewStyled(2)
        }

    }

    handleResize2();

    window.addEventListener("resize2", handleResize2);

    return () => {
      window.removeEventListener("resize2", handleResize2);
    };


  }, []);

  const [videoLoaded, setVideoLoaded] = useState(false);

  const scrollToOfferContainer = () => {
    scroller.scrollTo("offerContainer", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <>
    <Swiper
    slidesPerView={slidePerView}
    spaceBetween={20}
    loop={true}
    navigation={true}
    modules={[Pagination, Navigation]}
    className="mySwiper"
    >
    {showContentRows(data, "card")}
    </Swiper>
  </>
  );
}

export function Cards2(data) {
  const navigate = useNavigate();
  const [slidePerView, setSliderPerView] = useState(4);
  const [videoResponse, setVideoResponse] = useState("");
  const [src, setSrc] = React.useState("");

  const [myEdges, setMyEdges] = useState(0);
  const [myOffset, setMyOffset] = useState("");
  const [myUrl, setMyUrl] = useState("");

  useEffect(() => {
    function handleResize() {

        if (window.innerWidth > 1281) {
          setSliderPerView(5);
        } else if (window.innerWidth > 1025 && window.innerWidth < 1280) {
          setSliderPerView(4);
        } else if (window.innerWidth > 600 && window.innerWidth < 1024) {
          setSliderPerView(3);
        } else if (window.innerWidth > 480 && window.innerWidth < 599) {
          setSliderPerView(2);
        } else if (window.innerWidth < 479) {
          setSliderPerView(2);
        }

    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };


  }, []);

  const [videoLoaded, setVideoLoaded] = useState(false);



  return (
    <>
    <Swiper
    slidesPerView={slidePerView}
    spaceBetween={20}
    loop={true}
    navigation={true}
    modules={[Pagination, Navigation]}
    className="mySwiper"
    >
    {showContentRows(data, "styledCard")}
    </Swiper>

  </>
  );
}
