import axios from "axios";

const apiYouCastToken = axios.create({
  baseURL: process.env.REACT_APP_API_CEL_CASH_URL,
  timeout: 15000,
});

export const getAccessToken = async () => {
  const username = process.env.REACT_APP_USERNAME_CEL_CASH;
  const password = process.env.REACT_APP_PASSWORD_CEL_CASH;

  const basicAuth = btoa(`${username}:${password}`);
  const endpoint = "/token";

  const requestBody = {
    grant_type: "authorization_code",
    scope:
      "customers.read customers.write plans.read plans.write transactions.read transactions.write webhooks.write balance.read balance.write cards.read cards.write card-brands.read subscriptions.read subscriptions.write charges.read charges.write boletos.read",
  };

  try {
    const response = await apiYouCastToken.post(endpoint, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${basicAuth}`,
      },
    });

    return response.data.access_token;
  } catch (error) {
    console.error("Erro na chamada à API do token:", error);
    throw error;
  }
};
