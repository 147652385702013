import React from "react";
import "./scape.css";
import { animateScroll as scroll } from "react-scroll";

function ScapeButton() {

  // Função para verificar se a página atual é "congratulations"
  const isCongratulationsPage = () => {
    return location.pathname === "/congratulations";
  };

  const isDataChangePage = () => {
    return location.pathname === "/dataChange";
  };

  
  return (
    <div className="scapeContainer">
      <a href="https://bandnewstv.uol.com.br/">
        <button>
          <img
            className="scapeImg"
            src={require("../../assets/scared.png")}
            alt=""
          />
        </button>
      </a>
      {/* Adicione a condição para renderizar ou não a imagem de seta */}
      {!isCongratulationsPage() && !isDataChangePage() && (
        <img
          src={require("../../assets/arrow.png")}
          alt=""
          onClick={scroll.scrollToTop}
        />
      )}
    </div>
  );
}

export default ScapeButton;
