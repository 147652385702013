import React from "react";
import "../registerForm.css";
import PropTypes from "prop-types";

const Step1 = ({
  selectedPlan,
  handlePlanChange,
  availablePlans,
  handleInputChange,
  formValues,
}) => (
  <div>
    <div className="flexContent">
      <h3 className="title">Escolha a forma de pagamento:</h3>
      <div className="style-input-group">
        <select
          name="selectedPayment"
          onChange={handleInputChange} 
          value={formValues.selectedPayment}
          required
        >
          <option value="" disabled>
            Selecione uma forma de pagamento
          </option>
          <option value="creditcard">Crédito</option>
          <option value="pix">PIX</option>
    
        </select>
      </div>
      <h3 className="title">Dados Pessoais:</h3>
      <div className="style-input-group">
        <label className="style-input-filled">
          <input
            type="text"
            name="name"
            onChange={handleInputChange}
            value={formValues.name}
            required
          />
          <span className="style-input-label">Nome e Sobrenome</span>
          <span className="style-input-helper">
            Digite seu nome e sobrenome
          </span>
        </label>
      </div>
      <div className="style-input-group">
        <label className="style-input-filled">
          {
            <input
              maxLength={11}
              pattern="[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}"
              type="text"
              name="cpf"
              onChange={handleInputChange}
              value={formValues.cpf}
              required
            />
          }
          <span className="style-input-label">CPF</span>
          <span className="style-input-helper">Digite seu CPF</span>
        </label>
      </div>
      <div className="style-input-group">
        <label className="style-input-filled">
          <input
            maxLength={11}
            type="tel"
            name="phone"
            onChange={handleInputChange}
            value={formValues.phone}
            required
          />
          <span className="style-input-label">Telefone</span>
          <span className="style-input-helper">Digite seu telefone</span>
        </label>
      </div>
    </div>
    <div className="flexContent">
      <h3 className="title">Dados de Acesso:</h3>

      <div className="style-input-group">
        <label className="style-input-filled">
          <input
            type="email"
            required
            name="email"
            onChange={handleInputChange}
            value={formValues.email}
          />
          <span className="style-input-label">Email</span>
          <span className="style-input-helper">Digite seu email</span>
        </label>
      </div>

      <div className="style-input-group">
        <label className="style-input-filled">
          <input
            type="password"
            name="password"
            onChange={handleInputChange}
            value={formValues.password}
            required
          />
          <span className="style-input-label">Senha</span>
          <span className="style-input-helper">Digite sua senha</span>
        </label>
      </div>

      <div className="style-input-group">
        <label className="style-input-filled">
          <input
            type="password"
            name="confirmPassword"
            onChange={handleInputChange}
            value={formValues.confirmPassword}
            required
          />
          <span className="style-input-label">Confirme sua senha</span>
          <span className="style-input-helper">Confirme sua senha</span>
        </label>
      </div>
    </div>
  </div>
);

Step1.propTypes = {
  selectedPlan: PropTypes.shape({
    id: PropTypes.string,
  }),
  handlePlanChange: PropTypes.func,
  availablePlans: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  handleInputChange: PropTypes.func,
  formValues: PropTypes.shape({
    selectedPayment: PropTypes.string,
    name: PropTypes.string,
    cpf: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    confirmPassword: PropTypes.string,
  }),
};

export default Step1;