import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./offerCards.css";

export function OfferCards({ content }) {
  const location = window.location.pathname;
  const navigate = useNavigate();

  async function SubmitProfile() {
    console.log("content na função", content);
  }

  const handleSubscribe = async (offerID) => {
    try {
      localStorage.setItem("selectedOfferID", offerID);
      navigate("/register");
      window.scrollTo(0, 0);
    } catch (error) {
      console.error("Erro ao armazenar ID da assinatura:", error);
    }
  };

  return (
    <div className="offerAvailable">


      <div className="offerCard offerCardMinHeight3">
        <div
          className={
            location === "/"
              ? "offerIcon offerIconColor1"
              : "offerIcon offerIconColor2"
          }
        >
          <img src={require("../../assets/heart (1).png")}></img>
        </div>

        <div className="offerInfoContent">
          <h2>Mensal</h2>
          <ul>
            <li>
              <img src={require("../../assets/checked.png")}></img> Acesso ao
              Canal SEXPRIVÉ
            </li>
            <li>
              <img src={require("../../assets/checked.png")}></img> Acesso a
              diversos filmes e cenas
            </li>
          </ul>
          <div className="flexEnd">
            <h2>
              R$: <span>19,80</span>
            </h2>
            <button onClick={() => handleSubscribe("1")}>ASSINE AGORA!</button>
          </div>
        </div>
      </div>
      <div className="offerCard offerCardMinHeight3">
        <div
          className={
            location === "/"
              ? "offerIcon offerIconColor1"
              : "offerIcon offerIconColor2"
          }
        >
          <img src={require("../../assets/hot-deal.png")}></img>
        </div>

        <div className="offerInfoContent">
          <h2>Trimestral</h2>
          <ul>
            <li>
              <img src={require("../../assets/checked.png")}></img> Acesso ao
              Canal SEXPRIVÉ
            </li>
            <li>
              <img src={require("../../assets/checked.png")}></img> Acesso a
              diversos filmes e cenas
            </li>
            <li>
              <img src={require("../../assets/checked.png")}></img> 1 mês grátis
              em comparação ao mensal
            </li>
            <li>
              <img src={require("../../assets/checked.png")}></img> Valor Mensal equivalente
              a 9,90
            </li>
          </ul>
          <div className="flexEnd">
            <h2>
              R$: <span>29,70</span>
            </h2>
            <button onClick={() => handleSubscribe("2")}>ASSINE AGORA!</button>
          </div>
        </div>
      </div>
      <div className="offerCard offerCardMinHeight3">
        <div
          className={
            location === "/"
              ? "offerIcon offerIconColor1"
              : "offerIcon offerIconColor2"
          }
        >
          <img src={require("../../assets/butt.png")}></img>
        </div>

        <div className="offerInfoContent">
          <h2>Semestral</h2>
          <ul>
            <li>
              <img src={require("../../assets/checked.png")}></img> Acesso ao
              Canal SEXPRIVÉ
            </li>
            <li>
              <img src={require("../../assets/checked.png")}></img> Acesso a
              diversos filmes e cenas
            </li>
            <li>
              <img src={require("../../assets/checked.png")}></img> 3 meses
              grátis em comparação ao mensal
            </li>
            <li>
              <img src={require("../../assets/checked.png")}></img> Valor Mensal equivalente
              a 8,90
            </li>
          </ul>

          <div className="flexEnd">
            <h2>
              R$: <span>53,40</span>
            </h2>
            <button onClick={() => handleSubscribe("4")}>ASSINE AGORA!</button>
          </div>
        </div>
      </div>
      <div className="offerCard offerCardMinHeight3">
        <div
          className={
            location === "/"
              ? "offerIcon offerIconColor1"
              : "offerIcon offerIconColor2"
          }
        >
          <img src={require("../../assets/waist.png")}></img>
        </div>

        <div className="offerInfoContent">
          <h2>Anual</h2>
          <ul>
            <li>
              <img src={require("../../assets/checked.png")}></img> Acesso ao
              Canal SEXPRIVÉ
            </li>
            <li>
              <img src={require("../../assets/checked.png")}></img> Acesso a
              diversos filmes e cenas
            </li>
            <li>
              <img src={require("../../assets/checked.png")}></img> 7 meses
              grátis em comparação ao mensal
            </li>
            <li>
              <img src={require("../../assets/checked.png")}></img> Valor Mensal equivalente
              a 7,90
            </li>
          </ul>

          <div className="flexEnd">
            <h2>
              R$: <span>94,80</span>
            </h2>
            <button onClick={() => handleSubscribe("3")}>ASSINE AGORA!</button>
          </div>
        </div>
      </div>

      
    </div>
  );
}

OfferCards.propTypes = {
  content: PropTypes.bool.isRequired,
};


export function OfferCards2({ content }) {
  const location = window.location.pathname;
  const navigate = useNavigate();

  async function SubmitProfile() {
    console.log("content na função", content);
  }

  const handleSubscribe = async (offerID) => {
    try {
      localStorage.setItem("selectedOfferID", offerID);
      navigate("/register");
      window.scrollTo(0, 0);
    } catch (error) {
      console.error("Erro ao armazenar ID da assinatura:", error);
    }
  };

  return (
    <div className="offerAvailableContainer">
    
    <div className="offerAvailableLeft">
        <div className="offerAvailableLeftContainer">
          <h2>Com o plano mensal você paga</h2>
          <h1>R$ <span>8,90</span></h1>
          <h2>mas...</h2>

        </div>
    </div>

    <div className="offerAvailableCenter">
      <div className="offerAvailableCenterContainer">
        <h2 >Nos <span>2 PRIMEIROS MESES</span> é só</h2>
        <h1 >R$ <span>5,00</span></h1>
        <button onClick={() => handleSubscribe("1")}>ASSINE AGORA!</button>
        <p>Obs: valor referente à novas assinaturas</p>
      </div>



    </div>

    <div className="offerAvailableRight">
      <h1 className="offerAvailableCenterFont">Aproveite!</h1>
    </div>

      
    </div>
  );
}

OfferCards2.propTypes = {
  content: PropTypes.bool.isRequired,
};



