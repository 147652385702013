import React, { useEffect } from "react";

import { Routes, BrowserRouter, Route, Navigate } from "react-router-dom";

import Home from "./pages/home";
import PrivacyPolicy from "./pages/privacy";
import ContentWarning from "./components/contentWarning";
import UsageTerms from "./pages/termsOfUse";
import Offers from "./pages/offers";
import Register from "./pages/register";
import Congratulations from "./pages/congratulations";
import DataChange from "./pages/dataChange";
import ResetPassword from "./pages/resetPassword/resetPassword";
import PaymentPix from "./pages/paymentPix";

function AllRoutes() {
  const checkWarning = localStorage.getItem("agreedWarning");

  useEffect(() => {
    if (checkWarning === undefined || checkWarning === null) {
      localStorage.setItem("agreedWarning", false);
    }
  }, [checkWarning]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={checkWarning !== "true" ? <ContentWarning /> : <Home />}
        />
        <Route
          exact
          path="/politica-de-privacidade"
          element={<PrivacyPolicy />}
        />
        <Route exact path="/termos-de-uso" element={<UsageTerms />} />
        <Route
          exact
          path="/planos"
          element={checkWarning !== "true" ? <ContentWarning /> : <Offers />}
        />
        <Route
          exact
          path="/register"
          element={checkWarning !== "true" ? <ContentWarning /> : <Register />}
        />
        <Route exact path="/dataChange" element={<DataChange />} />
        <Route exact path="/congratulations" element={<Congratulations />} />
        <Route exact path="/resetPassword" element={<ResetPassword />} />
        <Route exact path="/paymentPix" element={<PaymentPix />} />
        <Route exact path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AllRoutes;