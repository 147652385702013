import React from "react";
import "../registerForm.css";
import PropTypes from "prop-types";

const Step2 = ({ searchcep, handleInputChange, formValues }) => (
  <div className="flexContent">
    <h3 className="title">Dados de Endereço:</h3>
    <div className="style-input-group">
      <label className="style-input-filled">
        <input
          pattern="\d{5}-\d{3}"
          maxLength={9}
          type="text"
          name="cep"
          id="cep"
          onBlur={(e) => searchcep(e.target.value)}
          onChange={handleInputChange}
          value={formValues.cep}
          required
        />
        <span className="style-input-label">CEP</span>
        <span className="style-input-helper">Digite seu CEP</span>
      </label>
    </div>
    <div className="style-input-group">
      <label className="style-input-filled">
        <input
          type="text"
          name="address"
          id="address"
          onChange={handleInputChange}
          value={formValues.address}
          data-input
          required
        />
        <span className="style-input-label">Logradouro</span>
        <span className="style-input-helper">Digite seu Logradouro</span>
      </label>
    </div>
    <div className="style-input-group">
      <label className="style-input-filled">
        <input
          type="text"
          name="number"
          onChange={handleInputChange}
          value={formValues.number}
          maxLength={10}
          required
        />
        <span className="style-input-label">Numero</span>
        <span className="style-input-helper">Digite seu Numero</span>
      </label>
    </div>
    <div className="style-input-group">
      <label className="style-input-filled">
        <input
          type="text"
          name="complement"
          onChange={handleInputChange}
          value={formValues.complement}
        />
        <span className="style-input-label">Complemento</span>
        <span className="style-input-helper">Digite seu Complemento</span>
      </label>
    </div>
    <div className="style-input-group">
      <label className="style-input-filled">
        <input
          type="text"
          name="state"
          id="state"
          onChange={handleInputChange}
          value={formValues.state}
          data-input
          required
        />
        <span className="style-input-label">Estado</span>
        <span className="style-input-helper">Digite seu Estado</span>
      </label>
    </div>
    <div className="style-input-group">
      <label className="style-input-filled">
        <input
          type="text"
          name="city"
          id="city"
          onChange={handleInputChange}
          value={formValues.city}
          data-input
          required
        />
        <span className="style-input-label">Cidade</span>
        <span className="style-input-helper">Digite sua Cidade</span>
      </label>
    </div>
    <div className="style-input-group">
      <label className="style-input-filled">
        <input
          type="text"
          name="neighborhood"
          id="neighborhood"
          onChange={handleInputChange}
          value={formValues.neighborhood}
          data-input
          required
        />
        <span className="style-input-label">Bairro</span>
        <span className="style-input-helper">Digite seu Bairro</span>
      </label>
    </div>
  </div>
);

Step2.propTypes = {
  searchcep: PropTypes.func,
  handleInputChange: PropTypes.func,
  formValues: PropTypes.shape({
    cep: PropTypes.string,
    address: PropTypes.string,
    number: PropTypes.string,
    complement: PropTypes.string,
    state: PropTypes.string,
    city: PropTypes.string,
    neighborhood: PropTypes.string,
  }),
};

export default Step2;
