import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import ScapeButton from "../../components/scapeButton/scape";
import "./index.css";

function Congratulations() {
  return (
    <div className="bgdCongratulations">
      <div className="congratulationsContainer">
        <Header />
        <ScapeButton />

        <div className="congratulationsContentDetails">
          <div className="congratulationsTitleDetails">
            <div className="sucessImageContainerBgd">
              <img src={require("../../assets/check.png")} alt="Success"></img>
            </div>
            <>
              <div className="prezado">
                <h1>Parabéns!! Seu pagamento foi confirmado.</h1>
              </div>
              <div className="seg">
                <h1>
                  Pedimos que aguarde uns instantes, suas informações de login serão enviadas no seu email em 30 minutos
                  ou menos. 
                </h1>
              </div>
            </>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Congratulations;
