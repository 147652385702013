import React, { useEffect, useState, useRef } from "react";
import result from "../../sessions/homepage";
import Header from "../../components/header/header";
import "./index.css";
import { Cards, Cards2 } from "../../components/cards/cards";
import SliderInitial from "../../components/slider/slider";
import Footer from "../../components/footer/footer";
import { useNavigate } from "react-router-dom";
import ScapeButton from "../../components/scapeButton/scape";
import { OfferCards, OfferCards2 } from "../../components/offerCards/offerCards";
import { animateScroll as scroll, scroller } from "react-scroll";
import BackgroundChannels from "../../assets/background_channels.png"
import { Link } from 'react-router-dom'

function Home() {
  const navigate = useNavigate();

  const [ativaCor, setAtivaCor] = useState(false);

  /* precisa remover esse evento pro slider nao gerar img aleatoria td hr */
  useEffect(function () {
    function posicaoScroll() {
      if (window.scrollY > 90) {
        setAtivaCor(true);
      } else {
        setAtivaCor(false);
      }
    }

    window.addEventListener("scroll", posicaoScroll);
  }, []);

  const scrollToOfferContainer = () => {
    scroller.scrollTo("offerContainer", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <div className="container">
      <Header acao={ativaCor}></Header>
      <ScapeButton />
      <SliderInitial result={result} />

      <div className="offerContainer">
        <div className="offerDescriptionInitial">

          <div className="offerDescriptionInitialCards">

            <div className="offerDescriptionCard">
              <div className="offerDescriptionCardTitle">
                <img src={require("../../assets/heart (1).png")}></img>
                <h3>ASSISTA QUANDO E DE ONDE QUISER</h3>
              </div>


              <div className="offerDescriptionCardContent">
                <p>Agora você pode ter prazer assistindo na TV, no
                  celular, tablet ou notebook! Basta baixar o App ou
                  acessar no seu navegador e aproveitar
                </p>
              </div>
            </div>

            <div className="offerDescriptionCard">
              <div className="offerDescriptionCardTitle">
                <img src={require("../../assets/butt.png")}></img>
                <h3>O MELHOR SEXO POR ASSINATURA</h3>
              </div>


              <div className="offerDescriptionCardContent">
                <p>Com catálogo renovado periodicamente a
                  Sexprivé Play é a pioneira e o mais importante
                  serviço de streaming dedicado ao gênero.
                </p>
              </div>
            </div>

            <div className="offerDescriptionCard">
              <div className="offerDescriptionCardTitle">
                <img src={require("../../assets/hot-deal.png")}></img>
                <h3>NOVIDADES QUENTINHAS. ASSINE!</h3>
              </div>


              <div className="offerDescriptionCardContent">
                <p>Mais de uma opção de canal, catálogo com
                  diversas categorias. Aqui você sempre terá uma
                  novidade para apimentar suas noites.
                </p>
              </div>
            </div>

          </div>

          <hr ></hr>
        </div>



        <div className="offerTitle">
          <h1>NOSSOS PLANOS</h1>
          <hr className="verticalLine"></hr>
          <h3>Preparamos promoções delirantes, escolha abaixo seu plano de assinatura do Sexprivé Play</h3>
        </div>

        {/* Alterar o redirecionamento do botão p form */}
        <OfferCards2 currentPage={"/"} />
      </div>



      <div className="demoVideoContainer">
        {result.data.response
          .filter(
            (item) =>
              item.type !== "images" &&
              item.title !== "Top Canais" &&
              item.title !== "",
          )
          .map((item, idx) => {

            if (item.background_image == null) {
            }
            return (
              <div key={idx} className={item.background_image !== null || item.title === "Canais" ? "categoryContainer1" : "categoryContainer2"}>
                <div className="categoryTitle">
                  <h1>{item.title}</h1>
                </div>

                {item.background_image !== null || item.title === "Canais"
                  ?
                  <div className="categoryBackgroundImageContainer" >

                    <div className="categoryBackgroundImage" style={{ backgroundImage: `url(${item.background_image !== null ? item.background_image : BackgroundChannels})`, backgroundSize: "cover", backgroundPositionX: "30%" }} >
                      <div className="categoryGradientBackground" ></div>
                    </div>


                    <div className="categoryCardsContainer">
                      <Cards2 values={item} />
                    </div>
                  </div>
                  :
                  <Cards values={item} />
                }

                <div className=""></div>
              </div>
            );
          })}
      </div>

      <div className="signatureAgainContainer">


        <section className="signatureSection">

          <h1>Dúvidas Frequentes</h1>

          <details className="signatureDetails">
            <summary className="signatureSummary">Como realizar a recuperação de senha?</summary>
            <p>Para recuperar sua senha, acesse a <Link className="colorLink" to="/datachange">Central do Assinante</Link> e selecione a opção &apos;Esqueci minha senha&apos;.</p>
          </details>

          <details className="signatureDetails">
            <summary className="signatureSummary">Como posso cancelar minha assinatura na plataforma?</summary>
            <p>Para cancelar sua assinatura, acesse a <Link className="colorLink" to="/datachange">Central do Assinante</Link>, faça o login na
              sua conta e procure pela opção Cancelar Assinatura na seção Minhas Assinaturas.</p>
          </details>

          <details className="signatureDetails">
            <summary className="signatureSummary">Conteúdos não estão funcionando corretamente nos dispositivos
              Apple?</summary>
            <p>Ops...Nossos conteúdos não estão acessíveis via aplicativo ou Safari da Apple.
              Sugerimos usar o navegador Chrome no iOS para acessar nossos serviços.
              Estamos trabalhando para melhorar isso. Agradecemos sua compreensão e
              estamos à disposição para ajudar.
            </p>
          </details>
          <details className="signatureDetails">
            <summary className="signatureSummary">Está com dificuldades para ver o vídeo?</summary>
            <p>Se você está tendo problemas para ver o conteúdo, verifique primeiro a
              velocidade da sua conexão com a internet. Se o problema continuar, por favor,
              envie um e-mail para <a className="colorLink" href="mailto:contato@sexpriveplay.com.br">contato@sexpriveplay.com.br</a>.</p>
          </details>
          <details className="signatureDetails">
            <summary className="signatureSummary">Você ainda não recebeu as informações de login, mesmo depois
              de confirmar o pagamento? </summary>
            <p>Para resolver essa questão, basta encaminhar um e-mail para o endereço <a className="colorLink" href="mailto:contato@sexpriveplay.com.br">contato@sexpriveplay.com.br</a>, nossa equipe estará pronta para ajudar.</p>
          </details>


        </section>

      </div>
      <Footer></Footer>
    </div>
  );
}

export default Home;
