import React, { useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import "./resetPassword.css";
import Header from "../../components/header/header";
import { apiYouCast } from "../../sessions/apiYoucast";
import { useNavigate } from 'react-router-dom';
import apiLocal from "../../sessions/apiLocal";


const ResetPassword = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [recoveryCode, setRecoveryCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await apiYouCast.get(`getCurrentUser/${email}`);
      const userData = response.data;
      // console.log("userData--> ", userData);

      if (userData) {
        try {
          await apiYouCast.post(`postTokenPassword/${email}`);

          toast.success(
            "Código de recuperação enviado com sucesso! Nosso e-mail pode demorar alguns segundos para aparecer na sua caixa de entrada aguarde um momento!",
            {
              duration: 5000,
            }
          );

          setStep(2);
          setLoading(false)
        } catch (error) {
          toast.error(
            "Erro ao enviar o código de recuperação. Tente novamente mais tarde."
          );
          console.error("Erro ao enviar o código de recuperação:", error);
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error(
          "Usuário não encontrado. Verifique o email e tente novamente."
        );
      }
    } catch (error) {
      console.error("Erro ao buscar usuário:", error);
      setLoading(false);
      toast.error(
        "Ocorreu um erro ao buscar o usuário. Por favor, tente novamente mais tarde."
      );
    }
  };

  const handleSubmitCodeAndNewPassword = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      console.log("EMAIL--> ", email);


      const response = await apiYouCast.get(`getRecoveryPassword/${email}`);
      console.log("RESPONSE--> ", response.data);

      const recoveryData = response.data;

      // Remover espaços em branco extras do código de recuperação
      const trimmedRecoveryCode = recoveryCode.trim();
      const saveRecoveryCode = recoveryData[0].saveRecoveryCode;
      const codeExpirationDate = new Date(recoveryData[0].codeExpirationDate);

      if (saveRecoveryCode === trimmedRecoveryCode) {
        const now = new Date();
        const differenceInMilliseconds = now - codeExpirationDate;
        const tenMinutesInMilliseconds = 10 * 60 * 1000;

        if (differenceInMilliseconds <= tenMinutesInMilliseconds) {
          // Verificar a complexidade da senha
          const passwordRegex =
            /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;

          if (!passwordRegex.test(password)) {
            setLoading(false);
            toast.error(
              "A senha deve ter no mínimo 8 caracteres, conter pelo menos uma letra maiúscula e um caractere especial."
            );
            return;
          }

          // Verificar se as senhas coincidem
          if (password !== confirmPassword) {
            setLoading(false);
            toast.error(
              "As senhas não coincidem. Por favor, verifique e tente novamente."
            );
            return;
          }

          const requestIDView = {
            data: {
              search: {
                wild_search: email,
              },
            },
          };

          const csmsId = await apiYouCast.post("getViewIDCSMS", requestIDView);
          const idView = csmsId.data.id;
          console.log("ID VIEW--> ", idView);

          // Verificar se o ID foi obtido corretamente
          if (!csmsId) {
            setLoading(false);
            toast.error(
              "Erro ao obter o ID do e-mail do CSMS. Por favor, tente novamente mais tarde."
            );
            return;
          }

          const requestBody = {
            email: email,
            new_password: password,
          };

          const requestBodyCSMS = {
            data: {
              viewers_id: idView,
              login: email,
              password: password,
            },
          };

          console.log(requestBody);

          await apiYouCast.put("putUserPassword", requestBody);

          await apiYouCast.post("putUserCSMS", requestBodyCSMS);

          setLoading(false);
          setStep(3);
          toast.success("Senha redefinida com sucesso!");
        } else {
          setLoading(false);
          // toast.error(
          //   "O código de recuperação expirou. Por favor, solicite um novo."
          // );
        }
      } else {
        setLoading(false);
        toast.error(
          "Código de recuperação inválido. Por favor, verifique e tente novamente."
        );
      }
    } catch (error) {
      console.error("Erro ao verificar código de recuperação:", error);
      setLoading(false);
      toast.error(
        "Ocorreu um erro ao verificar o código de recuperação. Por favor, tente novamente mais tarde."
      );
    }
  };

  const handleGoBack = () => {
    setLoading(false);
    setStep(1);
    setEmail("");
    setRecoveryCode("");
    setPassword("");
    setConfirmPassword("");
  };


  return (
    <>
      <Header />
      <div className="loginContainer">
        <div className="loginLeftContainer">
        </div>
        <div className="loginRightContainer">
          <div className="loginRightContent">
            {step === 1 && (
              <>
                <h1>Redefina sua senha</h1>
                <h3>Primeiro insira seu email para enviarmos o código de validação!</h3>
                <form className="form1" onSubmit={handleSubmitEmail}>
                  <div className="style-input-group">
                    <label className="style-input-filled">
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <span className="style-input-label">Email</span>
                      <span className="style-input-helper">Digite seu email</span>
                    </label>
                  </div>
                  <button className="buttonToken" type="submit">
                    {loading ? "Enviando..." : "Enviar código de recuperação"}
                  </button>
                </form>
              </>
            )}
            {step === 2 && (
              <>
                <h1>Redefina sua senha</h1>
                <form className="form2" onSubmit={handleSubmitCodeAndNewPassword}>
                  <div className="style-input-group">
                    <label className="style-input-filled">
                      <input
                        type="text"
                        name="recoveryCode"
                        value={recoveryCode}
                        onChange={(e) => setRecoveryCode(e.target.value)}
                        required
                      />
                      <span className="style-input-label">Código de Recuperação</span>
                      <span className="style-input-helper">Digite o código de recuperação</span>
                    </label>
                  </div>
                  <div className="style-input-group">
                    <label className="style-input-filled">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <span className="style-input-label">Nova Senha</span>
                      <span className="style-input-helper">Digite sua nova senha</span>
                    </label>
                  </div>
                  <div className="style-input-group">
                    <label className="style-input-filled">
                      <input
                        type={confirmPasswordVisible ? "text" : "password"}
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                      <span className="style-input-label">Confirme Nova Senha</span>
                      <span className="style-input-helper">Confirme sua nova senha</span>
                    </label>
                  </div>
                  <div className="submitButton">
                    <button type="submit" >
                      {loading ? "Enviando..." : "Redefinir Senha"}
                    </button>
                    <div className="goBack" onClick={handleGoBack}>
                      <a onClick={handleGoBack}>Se o código expirou, clique aqui para voltar e solicitar um novo código</a>
                    </div>
                  </div>
                </form>
              </>
            )}
            {step === 3 && (
              <div className="successMessage">
                <h2>Alteração de senha feita com sucesso!</h2>
                <button onClick={() => navigate('/')}>Voltar para plataforma</button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default ResetPassword;